import { Button, Dialog, Heading, Paragraph } from "@hexa-ui/components";
import React, { createContext, useContext, useState } from "react";

type DialogButtonAction = {
  action: () => void;
  dialogActionText: string;
  dialogActionButtonType: string;
};

const initialState = {
  openDialog: (
    _headerText: React.ReactNode,
    _contentText: React.ReactNode,
    _content?: React.ReactNode,
    _confirmActionButton?: DialogButtonAction,
    _cancelActionButton?: DialogButtonAction,
    _actionButtons?: DialogButtonAction[],
    _closeButton?: boolean,
    _styles?: any
  ) => {},
  closeDialog: () => {},
};

const DialogContext = createContext(initialState);

export const useDialog = () => useContext(DialogContext);

const DialogProvider = ({ children }) => {
  const [isOpen, setOpenDialog] = useState(false);
  const [closeButton, setCloseButton] = useState(false);
  const [styles, setStyles] = useState<any>();
  const [headerText, setHeaderText] = useState<React.ReactNode>("");
  const [contentText, setContentText] = useState<React.ReactNode>("");
  const [content, setContent] = useState<React.ReactNode>(<></>);
  const [confirmActionButton, setHandleConfirmAction] = useState({
    action: () => {},
    dialogActionText: "",
    dialogActionButtonType: "",
  });
  const [actionButtons, setHandleActionButtons] = useState([
    {
      action: () => {},
      dialogActionText: "",
      dialogActionButtonType: "",
    },
  ]);
  const [cancelActionButton, setHandleCancelAction] = useState({
    action: () => {},
    dialogActionText: "",
    dialogActionButtonType: "",
  });

  const openDialog = (
    headerText: React.ReactNode,
    contentText: React.ReactNode,
    content: React.ReactNode,
    confirmActionButton: DialogButtonAction,
    cancelActionButton: DialogButtonAction,
    actionButtons: DialogButtonAction[],
    closeButton: boolean,
    styles: any
  ) => {
    setHeaderText(headerText);
    setContentText(contentText);
    setContent(content);
    setHandleConfirmAction(confirmActionButton);
    setHandleCancelAction(cancelActionButton);
    setHandleActionButtons(actionButtons);
    setOpenDialog(true);
    setCloseButton(closeButton);
    setStyles(styles);
  };

  const closeDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirm = () => {
    confirmActionButton?.action();
  };

  const handleCancel = () => {
    if (cancelActionButton?.action) {
      cancelActionButton.action();
    } else {
      closeDialog();
    }
  };

  const mountTestId = (testId: string, value: string) => {
    const text = value.replace(/ /g, "-").replace(/,/g, "");
    return `${testId}-${text}`;
  };

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}

      {isOpen && (
        <Dialog.Root
          contentCss={styles ? styles : { width: "448px" }}
          closeButton={!!closeButton}
          onClose={() => handleCancel()}
          actions={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              {!!actionButtons &&
                actionButtons?.map((actionButton) => {
                  return (
                    <Button
                      data-testid={mountTestId(
                        "custom-dialog",
                        actionButton?.dialogActionText
                      )}
                      onClick={() => actionButton.action()}
                      size="medium"
                      style={{ marginRight: "0.9375rem" }}
                      variant={actionButton?.dialogActionButtonType as any}
                    >
                      {actionButton?.dialogActionText}
                    </Button>
                  );
                })}
              {!actionButtons && (
                <>
                  <Button
                    data-testid={`custom-dialog-continue-confirm`}
                    onClick={() => handleConfirm()}
                    size="medium"
                    style={{ marginRight: "0.9375rem" }}
                    variant={confirmActionButton?.dialogActionButtonType as any}
                  >
                    {confirmActionButton?.dialogActionText}
                  </Button>

                  <Button
                    onClick={() => handleCancel()}
                    data-testid={`custom-dialog-close`}
                    size="medium"
                    variant={cancelActionButton?.dialogActionButtonType as any}
                  >
                    {cancelActionButton?.dialogActionText}
                  </Button>
                </>
              )}
            </div>
          }
          title={
            <Heading width={"448px"} size="H2">
              {headerText}
            </Heading>
          }
          open={isOpen}
        >
          {content && <>{content}</>}
          {!content && <Paragraph>{contentText}</Paragraph>}
        </Dialog.Root>
      )}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
