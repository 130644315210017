import { Button, Grid, useProgressTracker } from "@hexa-ui/components";
import { TypeToast } from "admin-portal-shared-services";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CustomToastConfig } from "../../components/CustomToast/types";
import ExperimentValidationDialog from "../../components/DialogConfig/ExperimentValidationDialog/ExperimentValidationDialog";
import ExperimentMutationForm from "../../components/ExperimentMutationForm/ExperimentMutationForm";
import Stepper from "../../components/Stepper/Stepper";
import { tabsItems } from "../../components/Stepper/constants";
import CustomPageHeader from "../../components/molecules/CustomPageheader/CustomPageHeader";
import { useDialog } from "../../context/DialogContext/dialogProvider";
import { useMetrics } from "../../context/MetricsContext/metricProvider";
import { useSteppers } from "../../context/StepperContext/stepperProvider";
import { useToastConfiguration } from "../../context/ToastConfigContext/toastConfigProvider";
import { useGetValidationAudiencePocIdsQuery } from "../../hooks/queries/audiences/useGetValidationAudiencePocIdsQuery/useGetValidationAudiencePocIdsQuery";
import { useCreateExperiment } from "../../hooks/queries/experiments/useCreateExperiment";
import { useGetSingleExperimentQuery } from "../../hooks/queries/experiments/useGetSingleExperimentQuery";
import { usePublishExperiment } from "../../hooks/queries/experiments/usePublishExperiment";
import { usePublishExperimentValidation } from "../../hooks/queries/experiments/usePublishExperimentValidation/usePublishExperimentValidation";
import { useUpdateExperiment } from "../../hooks/queries/experiments/useUpdateExperiment";
import { useGetGuardrails } from "../../hooks/queries/guardrails/useGetGuardrails";
import {
  resetExperimentMutation,
  setExperimentMutation,
} from "../../store/ducks/ExperimentMutation/ExperimentMutationDuck";
import { useExperimentMutation } from "../../store/hooks/ExperimentMutation/ExperimentMutationHook";
import { ExperimentInputErrors, IExperiment } from "../../types/experiment";
import { useEnvProvider } from "../../utils/envProvider";
import { useCustomHistory } from "../../utils/routes";
import { StyledExperimentWrapper } from "../styles";
import CustomFileUpload, {
  FileTypes,
} from "./../../components/CustomFileUpload/CustomFileUpload";
import CustomToast from "./../../components/CustomToast/CustomToast";
import { baseExperiment } from "./../../mocks/experiment";
import {
  SET_TIMEOUT_TOAST_DURATION,
  cleanUnecessaryFields,
  getExportedMetrics,
  handleFieldError,
  mountAudiencesRequest,
  mountBasicInformationRequest,
  mountVariantRequest,
  prePopulateExperimentMutation,
} from "./utils";

export type ListOfPocs = {
  [key: string]: string[];
};

const step = {
  0: "step1",
  1: "step2",
  2: "step3",
  3: "step4",
};

const ExperimentMutation = (): JSX.Element => {
  const { Item } = Grid;

  const env = useEnvProvider();
  const vendorId = useMemo(() => env?.env?.vendorId, [env?.env?.vendorId]);

  const {
    experimentMutation: {
      id: experimentStateId,
      basicInformation,
      audiences,
      variations,
    },
  } = useExperimentMutation();

  const intl = useIntl();

  const { primaryMetrics, secondaryMetrics } = useMetrics();

  const dispatch = useDispatch();

  const { goTo } = useCustomHistory();

  const { id: experimentId } = useParams();

  const [isLoadingApiRequest, setIsLoadingApiRequest] =
    useState<boolean>(false);

  const {
    toast,
    handleToast: handleToastCondig,
    handleCloseToast,
  } = useToastConfiguration();

  const [toastExperimentApiConfig, setToastExperimentApiConfig] =
    useState<CustomToastConfig>({
      isOpened: false,
      type: TypeToast.INFO,
      message: "",
      duration: 3000,
    });

  const [completedSteps, _] = useState<number[]>([]);
  const tabs = tabsItems;

  const { openDialog, closeDialog } = useDialog();

  const {
    currentStep,
    reset,
    goToNext,
    goToPrevious,
    inRange,
    goTo: goToStep, // use it to Edit link in step4
  } = useProgressTracker({
    initialStep: 0,
    stepQuantity: tabs.length,
  });
  const [biggestStepActive] = useState<number>(currentStep);
  const dataGuardrails = useGetGuardrails();

  const queryClient = useQueryClient();

  const dataCreate = useCreateExperiment();

  const dataUpdate = useUpdateExperiment(experimentId);

  const dataPublish = usePublishExperiment(
    experimentId || dataCreate?.data?.id
  );

  const dataPublishExperimentValidation = usePublishExperimentValidation(
    experimentId || dataCreate?.data?.id
  );

  const dataExperiment = useGetSingleExperimentQuery(experimentId);

  const dataPocIds = useGetValidationAudiencePocIdsQuery(
    basicInformation?.country
  );

  const prepopulateExperimentMutationForm = (data: IExperiment) => {
    if (experimentStateId !== data.id && !!experimentId) {
      const experimentMutationForm = prePopulateExperimentMutation(data);
      dispatch(setExperimentMutation(experimentMutationForm));
    }
  };

  const prePopulateForm = () => {
    if (dataExperiment.data) {
      prepopulateExperimentMutationForm(dataExperiment.data);
    }
  };

  useEffect(() => {
    if (dataExperiment.isSuccess) {
      prePopulateForm();
    }

    window.addEventListener("beforeunload", () => {
      dispatch(resetExperimentMutation());
    });
  }, [dataExperiment.isSuccess]);

  useEffect(() => {
    return () => {
      dispatch(resetExperimentMutation());
      queryClient.clear();
      handleSteppers(0);
    };
  }, []);

  const [inputFormError, setinputFormError] = useState<ExperimentInputErrors>({
    step1: [],
    step2: [],
    step3: {
      variant_a: [],
      variant_b: [],
      variant_c: [],
      variant_d: [],
      variant_e: [],
      variant_f: [],
    },
  });

  const handlePublishExperiment = async () => {
    let isValidResponse: any = {};

    setIsLoadingApiRequest(true);
    isValidResponse = await dataPublish.mutateAsync(experimentStateId);

    if (
      isValidResponse &&
      (isValidResponse?.code?.includes("ERR_BAD_REQUEST") ||
        isValidResponse?.name?.includes("AxiosError"))
    ) {
      setToastExperimentApiConfig({
        isOpened: true,
        type: TypeToast.ERROR,
        message: isValidResponse?.response?.data?.errors[0]?.message,
        duration: SET_TIMEOUT_TOAST_DURATION,
      });
      setIsLoadingApiRequest(false);
      return;
    }

    setToastExperimentApiConfig({
      isOpened: true,
      type: TypeToast.SUCCESS,
      message: "Experiment published successfully",
      duration: SET_TIMEOUT_TOAST_DURATION,
    });
    setTimeout(() => {
      setIsLoadingApiRequest(false);
      goTo("");
    }, SET_TIMEOUT_TOAST_DURATION);
  };

  const { stepper, handleSteppers } = useSteppers();

  useEffect(() => {
    goToStep(stepper);
  }, [stepper]);

  const handlePublishExperimentValidationDialog = async () => {
    openDialog(
      "Send test version?",
      "",
      <ExperimentValidationDialog pocIds={dataPocIds?.data} />,
      null,
      null,
      [
        {
          action: () => {
            closeDialog();
          },
          dialogActionText: "No, go back",
          dialogActionButtonType: "secondary",
        },
        {
          action: () => {
            handlePublishExperimentValidation();
          },
          dialogActionText: "Yes, send",
          dialogActionButtonType: "primary",
        },
      ],
      true,
      {
        width: "400px",
        fontFamily: "Work Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
      }
    );
    return;
  };

  const handlePublishExperimentValidation = async () => {
    let isValidResponse: any = {};

    setIsLoadingApiRequest(true);
    isValidResponse =
      await dataPublishExperimentValidation.mutateAsync(experimentStateId);

    if (
      isValidResponse &&
      (isValidResponse?.code?.includes("ERR_BAD_REQUEST") ||
        isValidResponse?.name?.includes("AxiosError"))
    ) {
      handleToastCondig({
        isOpened: true,
        type: TypeToast.ERROR,
        message: isValidResponse?.response?.data?.errors[0]?.message,
        duration: SET_TIMEOUT_TOAST_DURATION,
      });

      setIsLoadingApiRequest(false);
      return;
    }

    setIsLoadingApiRequest(false);
    closeDialog();
    handleToastCondig({
      isOpened: true,
      type: TypeToast.SUCCESS,
      message:
        "Test version successfully sent. It will be reflected in the app in up to 6 hours.",
      duration: SET_TIMEOUT_TOAST_DURATION,
    });
    goTo(`experiment/${experimentStateId}`);
  };
  const validateStep = useCallback((stepData, stepIndex) => {
    if (stepIndex === 3) return true;

    const error = handleFieldError(stepData, stepIndex);
    const currentErrors = Array.isArray(error[step[stepIndex]])
      ? error[step[stepIndex]]
      : Object.values(error[step[stepIndex]]).flat();

    if (currentErrors.length > 0) {
      setinputFormError((prevState) => ({
        ...prevState,
        [step[stepIndex]]: error[step[stepIndex]],
      }));
      return false;
    }
    return true;
  }, []);

  const sendAPIRequest = useCallback(async (request, stepIndex) => {
    let isValidResponse;
    if (request?.id) {
      isValidResponse = await dataUpdate.mutateAsync({
        ...request,
        id: request.id,
      });
    } else {
      isValidResponse = await dataCreate.mutateAsync({
        ...request,
      });
    }

    return isValidResponse;
  }, []);

  useMemo(() => {
    if (dataCreate?.isSuccess) {
      dispatch(
        setExperimentMutation({
          id: dataCreate?.data?.id,
          basicInformation: {
            ...basicInformation,
            externalKey: dataCreate?.data?.externalKey,
          },
        })
      );
    }
  }, [dataCreate?.isSuccess]);

  const mountRequest = () => {
    const basicInformationRequest = mountBasicInformationRequest(
      basicInformation,
      vendorId[basicInformation.country],
      dataGuardrails?.data
    );
    const audiencesRequest = mountAudiencesRequest(audiences);
    const variationsRequest = {
      variations: mountVariantRequest(variations),
      id: experimentId ? experimentId : dataCreate?.data?.id,
    };
    if (currentStep === 0)
      return {
        ...basicInformationRequest,
        ...audiencesRequest,
        variations: variationsRequest.variations,
      };
    if (currentStep === 1)
      return { ...audiencesRequest, variations: variationsRequest.variations };
    return variationsRequest;
  };

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (currentStep === 3) return goTo("");

    const stepsRequest = {
      0: {
        ...basicInformation,
      },
      1: audiences,
      2: variations,
    };

    if (!validateStep(stepsRequest[currentStep], currentStep)) return;

    setIsLoadingApiRequest(true);

    let request = mountRequest();

    const isValidResponse = await sendAPIRequest(
      { ...request, id: experimentStateId },
      currentStep
    );
    setIsLoadingApiRequest(false);

    if (
      isValidResponse &&
      (isValidResponse?.code?.includes("ERR_BAD_REQUEST") ||
        isValidResponse?.name?.includes("AxiosError"))
    ) {
      setToastExperimentApiConfig({
        isOpened: true,
        type: TypeToast.ERROR,
        message: isValidResponse?.response?.data?.errors[0]?.message,
        duration: SET_TIMEOUT_TOAST_DURATION,
      });
      return;
    }

    if (event.type === "click") {
      goTo("");
      dispatch(resetExperimentMutation());
    } else {
      goToNext();
    }
  };

  const handleGoToPrevious = () => {
    setToastExperimentApiConfig((prev) => ({ ...prev, isOpened: false }));
    setinputFormError({
      step1: [],
      step2: [],
      step3: {
        variant_a: [],
        variant_b: [],
        variant_c: [],
        variant_d: [],
        variant_e: [],
        variant_f: [],
      },
    });
    goToPrevious();
  };

  const handleHeading = () => {
    if (experimentId) return "edit_experiment";
    return "create_experiment";
  };

  const handleImportFile = (file: IExperiment) => {
    const convertedData = prepareConvertedData(file);
    dispatchExperimentMutation(convertedData);
    manageToastNotification(convertedData);
    handleGoToNextStep();
  };

  const prepareConvertedData = (file: IExperiment) => {
    const basicData = baseExperiment;
    let data = { ...basicData, ...file, id: experimentId } as IExperiment;

    data = getExportedMetrics(data, primaryMetrics, secondaryMetrics);
    return cleanUnecessaryFields(data);
  };

  const dispatchExperimentMutation = (convertedData: IExperiment) => {
    dispatch(
      setExperimentMutation(prePopulateExperimentMutation(convertedData))
    );
  };

  const manageToastNotification = (convertedData: IExperiment) => {
    if (!convertedData.name) {
      return;
    }
    setToastExperimentApiConfig({
      isOpened: true,
      type: TypeToast.SUCCESS,
      message: "Import completed. Fill in the missing fields.",
      duration: SET_TIMEOUT_TOAST_DURATION,
    });
  };

  const handleGoToNextStep = () => {
    if (currentStep !== 0) goToStep(0);
  };

  const handleUploadError = (message: string) => {
    setToastExperimentApiConfig({
      isOpened: true,
      type: TypeToast.ERROR,
      message,
      duration: SET_TIMEOUT_TOAST_DURATION,
    });
  };

  const isLastStep = useCallback(() => {
    return currentStep >= tabs.length - 1;
  }, [currentStep, tabs.length]);

  const renderNextButton = useCallback(() => {
    if (isLastStep()) {
      return (
        <>
          <Button
            size="medium"
            variant="secondary"
            data-testid="publish-experiment-validation-button"
            type="button"
            onClick={() => handlePublishExperimentValidationDialog()}
            disabled={isLoadingApiRequest}
            style={{ marginRight: 10 }}
          >
            <FormattedMessage id="create_and_update_experiment.buttons.step4.publish_experiment_validation" />
          </Button>
          <Button
            size="medium"
            variant="primary"
            data-testid="publish-experiment-button"
            type="button"
            onClick={() => handlePublishExperiment()}
            disabled={isLoadingApiRequest}
          >
            <FormattedMessage id="create_and_update_experiment.buttons.step4.publish_experiment" />
          </Button>
        </>
      );
    }

    return (
      <Button
        size="medium"
        variant="primary"
        data-testid="next-button"
        type="submit"
        disabled={isLoadingApiRequest}
      >
        <FormattedMessage id="create_and_update_experiment.buttons.common.next" />
      </Button>
    );
  }, [isLastStep, handleSubmit]);

  const handleOpenedToast = useCallback(() => {
    setToastExperimentApiConfig((prev) => ({ ...prev, isOpened: false }));
  }, [toastExperimentApiConfig]);

  return (
    <StyledExperimentWrapper>
      <CustomToast
        dataTestid="custom-toast-dialog"
        isOpened={toastExperimentApiConfig.isOpened}
        duration={
          toastExperimentApiConfig.duration ?? SET_TIMEOUT_TOAST_DURATION
        }
        onClose={handleOpenedToast}
        type={toastExperimentApiConfig.type}
        message={toastExperimentApiConfig.message}
      />
      <Item
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0",
        }}
      >
        <CustomPageHeader
          translatedTitleKey={`page_title.${handleHeading()}`}
        />
        <Item
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xs={12}
          style={{
            display: "flex",
            margin: "32px 0 0 0",
            padding: "0",
          }}
        >
          <CustomFileUpload
            customFileUploadContent={{
              accept: FileTypes.JSON,
              message: `The file must be a JSON file and must be less than 1MB`,
              maxFileSize: 1000000,
              type: FileTypes.JSON,
            }}
            onUpload={(uploadResponse: IExperiment) => {
              handleImportFile(uploadResponse);
            }}
            onError={(message: string) => {
              handleUploadError(message);
            }}
          />
        </Item>
        <Item
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xs={12}
          style={{
            padding: 0,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Stepper
            tabs={tabs}
            completedSteps={completedSteps}
            biggestStepActive={biggestStepActive}
            currentStep={currentStep}
            customMargintop={32}
          />
        </Item>
        <form
          onSubmit={(values) => handleSubmit(values)}
          style={{ padding: 0 }}
        >
          <ExperimentMutationForm
            currentStep={currentStep}
            inputFormError={inputFormError}
            dataGuardrails={dataGuardrails?.data}
            setinputFormError={setinputFormError}
            goToStep={goToStep}
          />

          <Item style={{ padding: 0, width: "100%", marginTop: "32px" }}>
            <Item lg={6} md={12} sm={12} xl={6} xs={12} style={{ padding: 0 }}>
              <Button
                id="saveAndExit"
                size="medium"
                variant="secondary"
                data-testid="cancel-button"
                name="saveAndExit"
                type="button"
                onClick={(e) => handleSubmit(e)}
              >
                <FormattedMessage id="create_and_update_experiment.buttons.step1.save_and_exit" />
              </Button>
            </Item>
            <Item
              lg={6}
              md={12}
              sm={12}
              xl={6}
              xs={12}
              style={{ justifyContent: "end", padding: 0 }}
            >
              <Button
                size="medium"
                variant="secondary"
                data-testid="go-back-button"
                style={{ marginRight: 10 }}
                disabled={currentStep === 0}
                type="button"
                onClick={() => {
                  handleGoToPrevious();
                }}
              >
                Go Back
              </Button>
              {renderNextButton()}
            </Item>
          </Item>
        </form>
      </Item>
    </StyledExperimentWrapper>
  );
};

export default ExperimentMutation;
