import {
  Alert,
  Button,
  Chip,
  Grid,
  Heading,
  IconButton,
  Input,
  Paragraph,
  Select,
  TextLink,
} from "@hexa-ui/components";
import { Plus, Trash2, X } from "@hexa-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import JsonInputField from "../../../components/JsonInputField/JsonInputField";
import PocIdsDialog from "../../../components/PocIdsDialog/PocIdsDialog";
import { setExperimentMutation } from "../../../store/ducks/ExperimentMutation/ExperimentMutationDuck";
import { useExperimentMutation } from "../../../store/hooks/ExperimentMutation/ExperimentMutationHook";
import {
  STEP3_VARIATIONS_ALLOW_LIST_MAX_NUMBER,
  STEP3_VARIATIONS_MAX_NUMBER,
  VariationsSettingOptions,
  VariationsTypes,
} from "../../../types/common";
import {
  ExperimentInputErrors,
  IFormVariation,
} from "../../../types/experiment";
import * as ExperimentMutationStyles from "../styles";
import {
  VARIANT_TYPE,
  VARIANT_TYPE_SETTINGS,
  handleError,
  handleStep3FormFields,
} from "./../../../pages/ExperimentMutation/utils";
import * as Styles from "./styles";

type Props = {
  inputErrors: {
    variant_a: string[];
    variant_b: string[];
    variant_c: string[];
    variant_d: string[];
    variant_e: string[];
    variant_f: string[];
  };
  setinputFormError: React.Dispatch<
    React.SetStateAction<ExperimentInputErrors>
  >;
};

const Step3 = ({ inputErrors, setinputFormError }: Props): JSX.Element => {
  const { Container, Item } = Grid;

  const pocIdMainInputId = useRef(null);
  const [pocId, setPocId] = useState<string>("");

  const {
    experimentMutation: { variations: variants },
  } = useExperimentMutation();
  const dispatch = useDispatch();

  const intl = useIntl();

  const defaultVariant: IFormVariation = {
    id: "variant_id",
    name: "",
    description: "",
    type: "application-coded",
    settingOptions: [],
    json: "",
    viewers: [],
  };

  const [openDialog, setOpenDialog] = useState<{
    status: boolean;
    variantId: string;
  }>({ status: false, variantId: "" });
  const [viewer, setViewer] = useState<string>("");
  const [isDialogInputValid, setIsDialogInputValid] = useState<any>({
    status: true,
    message: "",
  });

  const handleFieldsBasedOnSelect = (
    inputName: string,
    value: string,
    variantId: string
  ) => {
    const variant = variants.find((variant) => variant.id === variantId);
    const newVariant = handleStep3FormFields({
      ...variant,
      [inputName]: value,
    });

    handleOnChangeVariant(inputName, value, variantId, newVariant);
  };

  const handleOnChangeVariant = (
    inputName: string,
    value: string,
    variantId: string,
    newVariant?: IFormVariation
  ) => {
    const errors = handleError(inputName, value, inputErrors[variantId]);
    const index = variants.findIndex((variant) => variant.id === variantId);
    dispatch(
      setExperimentMutation({
        variations: [
          ...variants.map((variant) => {
            if (variant.id === variantId) {
              return (
                newVariant || {
                  ...variant,
                  [inputName]: value,
                }
              );
            }
            return variant;
          }),
        ],
      })
    );
    errors &&
      setinputFormError({
        step1: [],
        step2: [],
        step3: { ...inputErrors, [variantId]: errors },
      });
  };

  const ids = ["a", "b", "c", "d", "e", "f"];
  const getLastId = (list) => {
    if (list.length === 0) return ids[0];

    return ids[list.length];
  };

  useEffect(() => {
    if (variants?.length === 0) {
      handleVariantCreation();
    }
  }, [variants]);

  const handleVariantCreation = () => {
    const newVariant = { ...defaultVariant };
    newVariant.id = `variant_${getLastId(variants)}`;
    dispatch(
      setExperimentMutation({
        variations: [...variants, newVariant],
      })
    );
  };

  const handleDeleteVariant = (variantId: string) => {
    const filteredVariations = variants.filter(
      (variant) => variant.id !== variantId
    );
    dispatch(
      setExperimentMutation({
        variations: filteredVariations.map((variant, index) => {
          return {
            ...variant,
            id: `variant_${ids[index]}`,
          };
        }),
      })
    );
  };

  const handleAddViewer = () => {
    setOpenDialog({ status: false, variantId: "" });
  };

  const handleDeleteViewer = (variantId: string, viewerId: string) => {
    const index = variants.findIndex((variant) => variant.id === variantId);
    const viewers = variants[index].viewers.filter(
      (viewer) => viewer !== viewerId
    );
    dispatch(
      setExperimentMutation({
        variations: [
          ...variants.map((variant) => {
            if (variant.id === variantId) {
              return {
                ...variant,
                viewers: viewers,
              };
            }
            return variant;
          }),
        ],
      })
    );
  };

  const getCopyTestContent = (variant: IFormVariation) => {
    return (
      variant?.settingOptions?.some(
        (option) => option === VARIANT_TYPE_SETTINGS.COPY_TEST
      ) && (
        <Item className="item-input-row flex-column mt-32 justify-space-between custom-width-1255">
          <ExperimentMutationStyles.ExperimentFormHeader
            css={{ marginTop: 0, width: "100%" }}
          >
            <Heading size="H5">Copy test</Heading>
          </ExperimentMutationStyles.ExperimentFormHeader>

          <Item className="item-input-row mt-16 justify-space-between custom-width-1255">
            <Styles.Step3FormControlCardInput
              css={{
                $$width_form_control_input: "616px",
                $$marginTop_form_control_input: "",
                $$height_form_control_input: "72px",
              }}
            >
              <Input
                label="Copy key"
                placeholder="Enter copy key"
                hasError={errorVerify(variant?.id, "androidStringKey")}
                errorText={`${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.copy_key" })}`}
                width={"100%"}
                value={variant?.androidStringKey}
                data-testid={`input-copy-key-${variant?.id}`}
                name="androidStringKey"
                onChange={(e: any) =>
                  handleOnChangeVariant(
                    "androidStringKey",
                    e.target.value,
                    variant?.id
                  )
                }
              />
            </Styles.Step3FormControlCardInput>
            <Styles.Step3FormControlCardInput
              css={{
                $$width_form_control_input: "617px",
                $$marginTop_form_control_input: "",
                $$height_form_control_input: "72px",
              }}
            >
              <Input
                label="Copy"
                name="stringValue"
                placeholder="Enter copy"
                hasError={errorVerify(variant?.id, "stringValue")}
                errorText={`${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.copy" })}`}
                value={variant?.stringValue}
                data-testid={`input-copy-${variant?.id}`}
                width={"100%"}
                onChange={(e: any) =>
                  handleOnChangeVariant(
                    "stringValue",
                    e.target.value,
                    variant?.id
                  )
                }
              />
            </Styles.Step3FormControlCardInput>
          </Item>
        </Item>
      )
    );
  };

  const getServerDrivenUiContent = (variant: IFormVariation) =>
    variant?.settingOptions?.some(
      (option) => option === VARIANT_TYPE_SETTINGS.SERVER_DRIVEN_UI
    ) && (
      <Item className="item-input-row flex-column mt-24 justify-space-between custom-width-1255">
        <ExperimentMutationStyles.ExperimentFormHeader
          css={{ marginTop: 0, width: "100%" }}
        >
          <Heading size="H5">SDUI (Server driven UI)</Heading>
        </ExperimentMutationStyles.ExperimentFormHeader>

        <Item className="item-input-row mt-16 justify-space-between custom-width-621">
          <Styles.Step3FormControlCardInput
            css={{
              $$width_form_control_input: "616px",
              $$marginTop_form_control_input: "",
              $$height_form_control_input: "72px",
            }}
          >
            <Input
              label="Container ID"
              placeholder="Enter label key"
              hasError={errorVerify(variant?.id, "containerId")}
              errorText={`${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.container_id" })}`}
              width={"100%"}
              data-testid={`input-container-id-${variant?.id}`}
              value={variant?.containerId}
              name="containerId"
              onChange={(e: any) =>
                handleOnChangeVariant(
                  "containerId",
                  e.target.value,
                  variant?.id
                )
              }
            />
          </Styles.Step3FormControlCardInput>
        </Item>
      </Item>
    );

  const errorVerify = (id: string, fieldName?: string) => {
    if (inputErrors) {
      const hasErrors = inputErrors[id]?.includes(fieldName);
      return hasErrors;
    }
  };

  const handleGoBack = () => {
    setIsDialogInputValid({
      status: true,
      message: "",
    });
    setOpenDialog({ status: false, variantId: "" });
    setPocId("");
  };

  return (
    <ExperimentMutationStyles.ExperimentStepsWrapper data-testid="step-3">
      <Alert
        style={{ width: "100%", marginTop: "32px" }}
        message={`${intl.formatMessage({ id: "create_and_update_experiment.labels.step3.alert.title" })}`}
      />
      <ExperimentMutationStyles.ExperimentStepsCard
        css={{
          $$customMarginTop: "16px",
          $$padding: 0,
          $$height_experiment_steps_wrapper: "108px",
        }}
        ref={{
          current: "[Circular]" as any,
        }}
        border="medium"
        elevated="small"
      >
        <ExperimentMutationStyles.StepColumnForm
          css={{
            $$flex_direction: "column",
            height: "60px",
          }}
        >
          <ExperimentMutationStyles.ExperimentFormHeader css={{ marginTop: 0 }}>
            <Heading size="H3">
              <FormattedMessage id="create_and_update_experiment.labels.step3.control_version.title" />
            </Heading>
          </ExperimentMutationStyles.ExperimentFormHeader>
          <span className="text-secondary-color">
            <FormattedMessage id="create_and_update_experiment.labels.step3.control_version.paragraph" />
          </span>
        </ExperimentMutationStyles.StepColumnForm>
      </ExperimentMutationStyles.ExperimentStepsCard>

      <ExperimentMutationStyles.ExperimentStepsCard
        css={{
          $$customMarginTop: "16px",
          $$padding: "0 0 24px 0",
          $$height_experiment_steps_wrapper: "auto",
        }}
        ref={{
          current: "[Circular]" as any,
        }}
        border="medium"
        elevated="small"
      >
        <ExperimentMutationStyles.ExperimentFormHeader>
          <Heading size="H3">
            <FormattedMessage id="create_and_update_experiment.labels.step3.title" />
          </Heading>
        </ExperimentMutationStyles.ExperimentFormHeader>

        {variants?.length > 0 &&
          variants?.map((variant, index) => (
            <>
              <Styles.Step3VariantsFormColumn
                key={variant?.id}
                id="Step3VariantsFormColumn"
                className="margin-config"
                css={{ width: "1255px" }}
              >
                <Item className="item-form-header">
                  <ExperimentMutationStyles.ExperimentFormHeader
                    css={{ marginTop: 0 }}
                  >
                    <Heading size="H4">
                      Variant{" "}
                      {String.fromCharCode(
                        "a".charCodeAt(0) + index
                      ).toLocaleUpperCase()}
                    </Heading>
                  </ExperimentMutationStyles.ExperimentFormHeader>
                  {variants?.length > 1 && (
                    <IconButton
                      icon={Trash2}
                      data-testid={`icon-delete-variant-${variant?.id}`}
                      variant="tertiary"
                      type="button"
                      onClick={() => handleDeleteVariant(variant?.id)}
                    />
                  )}
                </Item>

                <Item className="item-input-row mt-24 justify-space-between custom-width-1255">
                  <Styles.Step3FormControlCardInput
                    css={{
                      $$width_form_control_input: "619px",
                      $$marginTop_form_control_input: "",
                      $$height_form_control_input: "72px",
                    }}
                  >
                    <Input
                      label="Variant name"
                      placeholder="Enter variant name"
                      value={variant?.name}
                      hasError={errorVerify(variant?.id, "name")}
                      errorText={`${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.variant_name" })}`}
                      onChange={(e: any) =>
                        handleOnChangeVariant(
                          "name",
                          e.target.value,
                          variant?.id
                        )
                      }
                      data-testid={`input-variant-name-${variant?.id}`}
                      width={"100%"}
                      name="name"
                    />
                  </Styles.Step3FormControlCardInput>
                  <Styles.Step3FormControlCardInput
                    css={{
                      $$width_form_control_input: "617px",
                      $$marginTop_form_control_input: "",
                      $$height_form_control_input: "72px",
                    }}
                  >
                    <Input
                      label="Variant description"
                      placeholder="Enter variant description"
                      width={"100%"}
                      value={variant?.description}
                      hasError={errorVerify(variant?.id, "description")}
                      errorText={`${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.variant_description" })}`}
                      data-testid={`input-variant-description-${variant?.id}`}
                      name="description"
                      onChange={(e: any) =>
                        handleOnChangeVariant(
                          "description",
                          e.target.value,
                          variant?.id
                        )
                      }
                    />
                  </Styles.Step3FormControlCardInput>
                </Item>

                <Item className="item-input-row mt-32 justify-space-between custom-width-621">
                  <Styles.Step3FormControlCardInput
                    css={{
                      $$width_form_control_input: "204px",
                      $$marginTop_form_control_input: "",
                      $$height_form_control_input: "72px",
                    }}
                  >
                    <Select.Root
                      id="input-type"
                      label="Type"
                      defaultValue={"Application coded"}
                      value={variant?.type}
                      onChange={(e: any) =>
                        handleFieldsBasedOnSelect("type", e, variant?.id)
                      }
                      data-testid={`select-type-${variant?.id}`}
                      name="type"
                    >
                      {VariationsTypes.map((item) => (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          data-testid={`type-${item.id}`}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select.Root>
                  </Styles.Step3FormControlCardInput>
                  <Styles.Step3FormControlCardInput
                    css={{
                      $$width_form_control_input: "400px",
                      $$marginTop_form_control_input: "",
                      $$height_form_control_input: "72px",
                    }}
                  >
                    <Select.Root
                      label="Setting options"
                      disabled={
                        variant?.type === VARIANT_TYPE.APPLICATION_CODED
                      }
                      onChange={(e: any) =>
                        handleFieldsBasedOnSelect(
                          "settingOptions",
                          e,
                          variant?.id
                        )
                      }
                      placeholder="Select"
                      multiple
                      shape="square"
                      name="settingOptions"
                      value={variant?.settingOptions}
                      error={
                        errorVerify(variant?.id, "settingOptions")
                          ? intl.formatMessage({
                              id: "create_and_update_experiment.error_messages.step3.setting_options",
                            })
                          : ""
                      }
                      data-testid={`setting-options-${variant?.id}`}
                    >
                      {VariationsSettingOptions.map((item) => (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          data-testid={`${item.id}-${variant?.id}`}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select.Root>
                  </Styles.Step3FormControlCardInput>
                </Item>

                {variant?.type === VARIANT_TYPE.PLATFORM_SETTING && (
                  <>
                    {getCopyTestContent(variant)}
                    {getServerDrivenUiContent(variant)}
                  </>
                )}
                {(variant?.type === VARIANT_TYPE.APPLICATION_CODED ||
                  variant?.settingOptions?.includes(
                    VARIANT_TYPE_SETTINGS.SERVER_DRIVEN_UI
                  )) && (
                  <Item className="item-input-row mt-24 justify-space-between custom-width-1255">
                    <Styles.Step3FormControlCardInput
                      css={{
                        $$width_form_control_input: "1256px",
                        $$marginTop_form_control_input: "",
                        $$height_form_control_input: "106px",
                      }}
                    >
                      <JsonInputField
                        jsonValue={variant?.json}
                        name="json"
                        inputErrors={errorVerify(variant?.id, "json")}
                        id={`textarea-json-${variant?.id}`}
                        label="JSON"
                        width={"100%"}
                        variant={variant}
                        changeAction={handleOnChangeVariant}
                      />
                    </Styles.Step3FormControlCardInput>
                  </Item>
                )}

                <Item
                  lg={12}
                  md={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  style={{ marginTop: 30 }}
                >
                  <div style={{ width: "100%" }}>
                    <Paragraph size="small" style={{ fontWeight: 600 }}>
                      <FormattedMessage id="create_and_update_experiment.labels.step3.variants.section_allow_list.title" />{" "}
                      <span
                        style={{
                          fontSize: "var(--fontSizes-1)",
                          fontWeight: "var(--fontWeights-normal)",
                          color: "var(--colors-interfaceLabelSecondary)",
                          paddingBottom: "var(--space-1)",
                        }}
                      >
                        (
                        <FormattedMessage id="create_and_update_experiment.labels.step3.variants.section_allow_list.optional" />
                        )
                      </span>
                    </Paragraph>
                    <div
                      style={{
                        marginTop: 10,
                        display: "flex",
                        alignItems: "center",
                      }}
                      data-testid="div-select-viewers"
                    >
                      {variant?.viewers?.map((viewer, index) => (
                        <div key={`${viewer}-${index}`}>
                          <Chip.Root
                            disabled={true}
                            style={{ marginRight: 10 }}
                          >
                            <Chip.Label
                              data-testid={`viewer-${variant?.id}-${viewer}`}
                            >
                              {`POC: ${viewer}`}
                            </Chip.Label>
                            <IconButton
                              style={{ marginLeft: "5px" }}
                              icon={X}
                              data-testid={`delete-chip-${variant?.id}-${viewer.replace(" ", "-")}`}
                              variant="tertiary"
                              type="button"
                              size="tiny"
                              onClick={() =>
                                handleDeleteViewer(variant?.id, viewer)
                              }
                            />
                          </Chip.Root>
                        </div>
                      ))}

                      <div>
                        <TextLink
                          style={{
                            pointerEvents:
                              variant?.viewers &&
                              variant?.viewers?.length >=
                                STEP3_VARIATIONS_ALLOW_LIST_MAX_NUMBER
                                ? ("none" as React.CSSProperties["pointerEvents"])
                                : ("auto" as React.CSSProperties["pointerEvents"]),
                          }}
                          data-testid="link-add-viewer"
                          onClick={() =>
                            setOpenDialog({
                              status: true,
                              variantId: variant?.id,
                            })
                          }
                        >
                          +{" "}
                          <FormattedMessage id="create_and_update_experiment.buttons.step3.add_viewer" />
                        </TextLink>
                      </div>
                      <PocIdsDialog
                        openDialog={openDialog}
                        handleAddViewer={handleAddViewer}
                        handleGoBack={handleGoBack}
                        variantId={openDialog?.variantId}
                      />
                    </div>
                  </div>
                </Item>
                <Styles.HorizontalDivision orientation="horizontal" />
              </Styles.Step3VariantsFormColumn>
            </>
          ))}
        <Styles.AddVariantArea>
          <Button
            icon={Plus}
            data-testid="button-create-variant"
            size="medium"
            type="button"
            leading
            variant="secondary"
            onClick={() => handleVariantCreation()}
            disabled={variants?.length >= STEP3_VARIATIONS_MAX_NUMBER}
          >
            <FormattedMessage id="create_and_update_experiment.buttons.step3.create_variant" />
          </Button>
        </Styles.AddVariantArea>
      </ExperimentMutationStyles.ExperimentStepsCard>
    </ExperimentMutationStyles.ExperimentStepsWrapper>
  );
};

export default Step3;
