import { Action, Alert } from "@hexa-ui/components";

type AlertsProps = {
  type: "info" | "warning" | "error" | "success";
  message: string;
  actions?: [firstAction: Action, secondAction?: Action];
};

const Alerts = ({ type, message, actions }: AlertsProps) => {
  return (
    <Alert
      data-testid={`alert-${type}`}
      style={{ width: "100%" }}
      type={type}
      message={message}
      actions={actions}
    />
  );
};
export default Alerts;
