import { Grid, ProgressTracker } from "@hexa-ui/components";

type StepperProps = {
  tabs: {
    label: string;
    alertMessage?: string;
    detail?: string;
  }[];
  currentStep: number;
  customMargintop?: string;
  completedSteps?: number;
  biggestStepActive?: number;
};

const Stepper = ({
  tabs,
  currentStep,
  customMargintop,
  completedSteps,
  biggestStepActive,
}): JSX.Element => {
  const { Root, Header, Step, StepLabel } = ProgressTracker;

  return (
    <Grid.Item style={{ marginTop: customMargintop }}>
      <ProgressTracker.Root
        currentStep={currentStep}
        orientation="horizontal"
        size="large"
      >
        <ProgressTracker.Header>
          {tabs.map((item, index) => (
            <ProgressTracker.Step
              index={index}
              key={index}
              completed={index < currentStep}
              disabled={index > currentStep}
            >
              <ProgressTracker.StepLabel
                alertMessage={item.alertMessage}
                label={item.label}
                detail={item.detail}
              />
            </ProgressTracker.Step>
          ))}
        </ProgressTracker.Header>
      </ProgressTracker.Root>
    </Grid.Item>
  );
};

export default Stepper;
