import { styled } from "@hexa-ui/theme";

export const DialogContentContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  height: "216px",
});

export const DialogPocIdsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
});
