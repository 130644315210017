import { IActionLink } from "@/types/common";
import { IExperiment } from "@/types/experiment";
import {
  Grid,
  Heading,
  Paragraph,
  TextLink,
  Tooltip,
} from "@hexa-ui/components";
import { Copy } from "@hexa-ui/icons";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { handleCopyText } from "../../../pages/ExperimentMutation/utils/";
import CardHeading from "../../CardHeading/CardHeading";

const BasicInformation = ({
  experiment,
  action,
  isPreview,
}: {
  experiment: IExperiment;
  action?: IActionLink;
  isPreview?: boolean;
}): JSX.Element => {
  const { Item } = Grid;
  const intl = useIntl();

  const TextLinkWrapper = {
    display: "flex",
    flexWrap: "wrap" as React.CSSProperties["flexWrap"],
    wordWrap: "break-word" as React.CSSProperties["wordWrap"],
    wordBreak: "break-all" as React.CSSProperties["wordWrap"],
  };

  const [copied, setCopied] = useState("copy_external_key");

  const handleClipboard = (text: string) => {
    handleCopyText(text);
    setCopied("copied");
    setTimeout(() => {
      setCopied("copy_external_key");
    }, 3000);
  };

  return (
    <Item
      sm={12}
      className="px-0"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <CardHeading
        heading={
          <Heading size="H3">
            <FormattedMessage id="experiment_details.basic_information.basic_information" />
          </Heading>
        }
        action={action}
      />
      <Item lg={12} md={12} sm={12} xl={2} xs={12} style={{ padding: 0 }}>
        <Item lg={6} md={6} sm={12} xl={6} xs={12} style={{ minWidth: "50%" }}>
          <div style={{ width: "100%" }}>
            <div style={{ marginTop: 30 }}>
              <Heading className="labelHeading" size="H5">
                <FormattedMessage id="experiment_details.basic_information.name" />
              </Heading>
              <Paragraph className="mt-1">{experiment?.name}</Paragraph>
            </div>

            <div style={{ marginTop: 30 }}>
              <Heading className="labelHeading" size="H5">
                <FormattedMessage id="experiment_details.basic_information.hypothesis" />
              </Heading>
              <Paragraph className="mt-1">{experiment?.hypothesis}</Paragraph>
            </div>

            <div style={{ marginTop: 30 }}>
              <Heading className="labelHeading" size="H5">
                <FormattedMessage id="experiment_details.basic_information.country" />
              </Heading>
              <Paragraph className="mt-1">
                <FormattedMessage
                  id={`countries.${experiment?.country}.label`}
                />
              </Paragraph>
            </div>
          </div>
        </Item>
        <Item lg={6} md={6} sm={12} xl={6} xs={12}>
          <div style={{ width: "100%" }}>
            <div style={{ marginTop: 30 }}>
              <Heading className="labelHeading" size="H5">
                <FormattedMessage id="experiment_details.basic_information.description" />
              </Heading>
              <Paragraph className="mt-1">{experiment?.description}</Paragraph>
            </div>

            <div style={{ marginTop: 30 }}>
              <Heading className="labelHeading" size="H5">
                <FormattedMessage id="experiment_details.basic_information.documentation" />
              </Heading>
              <TextLink
                className="mt-1"
                data-testid="documentation-link"
                onClick={() => window.open(experiment?.documentationLink)}
                style={TextLinkWrapper}
              >
                {experiment?.documentationLink}
              </TextLink>
            </div>
            {isPreview && (
              <div style={{ marginTop: 30 }}>
                <Heading className="labelHeading" size="H5">
                  <FormattedMessage id="experiment_details.external_key.label" />
                </Heading>
                <div
                  className="mt-1"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Tooltip
                    text={intl.formatMessage({
                      id: `experiment_details.external_key.${copied}`,
                    })}
                    placement="top"
                  >
                    {experiment?.externalKey}
                    <Copy
                      onClick={() => handleClipboard(experiment?.externalKey)}
                      style={{ cursor: "pointer", color: "#0363c4" }}
                      size="large"
                    />
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </Item>
      </Item>
    </Item>
  );
};

export default BasicInformation;
