import { ExperimentStatusList } from "./../../../types/experiment";

export const generateJsonFile = <T>(data: T, filename: string) => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(data)
  )}`;
  const link = document.createElement("a");
  link.href = jsonString;
  link.download = `${filename}.json`;

  link.click();
};

export const hasConfidenceLevelStatus = (status: string) => {
  if (!status) return;

  return (
    status === ExperimentStatusList.CANCELLED ||
    status === ExperimentStatusList.FINISHED ||
    status === ExperimentStatusList.STARTED
  );
};
