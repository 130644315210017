import { Grid } from "@hexa-ui/components";
import styled from "styled-components";

export const PageWrapper = styled(Grid.Container)({
  display: "block",
  width: "1304px",
  padding: "0",
  margin: "0 16px 0 90px",
  "@media (min-width: 1500px)": {
    margin: "0 auto",
  },
  fontFamily: "Work Sans",
});
