import styled from "styled-components";

export const StyledExperimentListTable = styled.div({
  width: "100%",
  tbody: {
    tr: {
      "&:nth-child(even)": {
        backgroundColor: "#fafafa",
      },
    },
  },
});
