import { TypeToast } from "admin-portal-shared-services";
import { createContext, useContext, useState } from "react";
import { SET_TIMEOUT_TOAST_DURATION } from "../../components/CustomDialog/constants";
import CustomToast from "../../components/CustomToast/CustomToast";

type ToastContextProps = {
  children: React.ReactNode;
};
type ToastConfig = {
  isOpened: boolean;
  message?: string;
  duration?: number;
  type?: TypeToast;
};
type ToastConfigType = {
  toast: ToastConfig;
  handleToast: (toastConfig: any) => void;
  handleCloseToast: () => void;
};

const initialState = {
  toast: {
    isOpened: false,
    type: TypeToast.INFO,
    message: "",
    duration: SET_TIMEOUT_TOAST_DURATION,
  },

  handleToast: (toastConfig: any) => {},
  handleCloseToast: () => {},
};

const ToastConfigContext = createContext<ToastConfigType>(initialState);

export const useToastConfiguration = () => useContext(ToastConfigContext);

const ToastConfigProvider = ({ children }: ToastContextProps) => {
  const [toast, setToast] = useState<ToastConfig>(initialState.toast);

  const handleToast = (toastConfig: ToastConfig) => {
    setToast(toastConfig);
  };

  const handleCloseToast = () => {
    setToast({
      isOpened: false,
    });
  };

  return (
    <ToastConfigContext.Provider
      value={{ toast, handleToast, handleCloseToast }}
    >
      {children}
      {toast.isOpened && (
        <CustomToast
          type={toast.type}
          isOpened={toast.isOpened}
          dataTestid={""}
          message={toast.message}
          duration={toast.duration}
          showCloseButton={false}
          onClose={handleCloseToast}
        />
      )}
    </ToastConfigContext.Provider>
  );
};

export default ToastConfigProvider;
