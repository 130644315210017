import { useMutation } from "react-query";
import { stopExperimentValidation } from "../../../../services/experiment/experimentService";
export const useStopExperimentValidation = (experimentId: string) => {
  const mutation = useMutation({
    mutationFn: (experimentId: string) =>
      stopExperimentValidation(experimentId),
    mutationKey: ["stopExperimentValidation", experimentId],
  });
  return mutation;
};
