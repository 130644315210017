import * as ExperimentMutationSteps from "../ExperimentMutationSteps";
import { ExperimentMutationFormProps } from "./types";

const ExperimentMutationForm = ({
  inputFormError,
  setinputFormError,
  dataGuardrails,
  currentStep,
  goToStep,
}: ExperimentMutationFormProps) => {
  const steps = {
    0: (
      <ExperimentMutationSteps.Step1
        inputError={inputFormError.step1}
        guardrails={dataGuardrails}
        setinputFormError={setinputFormError}
      />
    ),
    1: (
      <ExperimentMutationSteps.Step2
        inputError={inputFormError.step2}
        setinputFormError={setinputFormError}
      />
    ),
    2: (
      <ExperimentMutationSteps.Step3
        inputErrors={inputFormError.step3}
        setinputFormError={setinputFormError}
      />
    ),
    3: <ExperimentMutationSteps.Step4 goToStep={goToStep} />,
  };
  return steps[currentStep];
};

export default ExperimentMutationForm;
