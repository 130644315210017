import { useUserMetadata } from "admin-portal-shared-services";
import { getAvailableCountries } from "../../pages/ExperimentMutation/constants";
import { Countries } from "../../types/experiment";

const useCustomUserMedata = () => {
  const {
    data: medatada,
    isLoading: isCountriesLoading,
    hasError,
  } = useUserMetadata();

  const handleSupportedCountries = (): Countries => {
    const countries: Countries = getAvailableCountries();

    Object.keys(countries).forEach((country) => {
      if (!medatada?.supportedCountries?.includes(country))
        delete countries[country];
    });

    return countries;
  };

  return {
    handleSupportedCountries,
    isCountriesLoading,
  };
};

export default useCustomUserMedata;
