import { IActionLink } from "@/types/common";
import { IExperiment } from "@/types/experiment";
import { Grid, Heading, Paragraph, TextLink } from "@hexa-ui/components";
import { Edit2 } from "@hexa-ui/icons";
import { FormattedMessage } from "react-intl";
import { formatedDate } from "../../../services/utils";
import CardHeading from "../../CardHeading/CardHeading";
import "../../style.css";

const ExperimentSchedule = ({
  experiment,
  action,
  setIsEstimatedEndDateDialogOpen,
}: {
  experiment: IExperiment;
  action?: IActionLink;
  setIsEstimatedEndDateDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}): JSX.Element => {
  const { Item } = Grid;
  return (
    <Item
      className="px-0"
      sm={12}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <CardHeading
        heading={
          <Heading size="H3">
            <FormattedMessage id="experiment_details.schedule.schedule" />
          </Heading>
        }
        action={action}
      />
      <Item
        lg={12}
        md={12}
        sm={12}
        xl={2}
        xs={12}
        style={{ marginTop: 32, padding: 0 }}
      >
        <Item lg={3} md={3} sm={12} xl={3} xs={12}>
          <div style={{ width: "100%" }}>
            <div>
              <Heading className="labelHeading" size="H5">
                <FormattedMessage id="experiment_details.schedule.start_date" />
              </Heading>
              {experiment?.startDate ? (
                <Paragraph className="mt-1">
                  {formatedDate(experiment?.startDate)}
                </Paragraph>
              ) : (
                <Paragraph className="mt-1">-</Paragraph>
              )}
            </div>
          </div>
        </Item>
        <Item lg={3} md={3} sm={12} xl={3} xs={12}>
          <div style={{ width: "100%" }}>
            <div>
              <Heading className="labelHeading" size="H5">
                <FormattedMessage id="experiment_details.schedule.end_date" />
              </Heading>
              <Paragraph
                className="mt-1"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "auto",
                  alignItems: "center",
                }}
              >
                {experiment?.endDate && (
                  <span>{formatedDate(experiment?.endDate)}</span>
                )}
                {experiment?.status === "STARTED" && (
                  <TextLink
                    data-testid="text-link-end-date"
                    onClick={() => setIsEstimatedEndDateDialogOpen(true)}
                    className="default-link"
                    style={{
                      marginTop: 0,
                      marginLeft: experiment?.endDate ? "15px" : 0,
                    }}
                  >
                    <Edit2 size="medium" />
                    <FormattedMessage id="experiment_details.schedule.edit_date" />
                  </TextLink>
                )}
              </Paragraph>
              {!experiment?.endDate &&
                (experiment?.status === "DRAFT" ||
                  experiment?.status === null) && (
                  <Paragraph className="mt-1 text-tips">
                    <FormattedMessage id="experiment_details.schedule.empty_estimated_end_date" />
                  </Paragraph>
                )}
            </div>
          </div>
        </Item>
        <Item lg={4} md={4} sm={12} xl={4} xs={12}>
          <div style={{ width: "100%" }}>
            <div>
              <Heading className="labelHeading" size="H5">
                <FormattedMessage id="experiment_details.schedule.duration" />
              </Heading>
              <Paragraph className="mt-1 text-tips">
                <FormattedMessage id="experiment_details.schedule.duration_paragraph" />
              </Paragraph>
            </div>
          </div>
        </Item>
      </Item>
    </Item>
  );
};
export default ExperimentSchedule;
