import Api from "../../Api/Api";
import { envs } from "../../config/env";
import {
  ExportExperiment,
  IExperiment,
  IExperimentRequest,
  IExperimentResponse,
} from "../../types/experiment";

export async function getExperiments(
  projection: string,
  pageSize?: number,
  page?: number
): Promise<IExperimentResponse> {
  try {
    const response = await Api.get({
      url: `${envs.BASE_URL}/experiments/list`,
      config: {
        params: {
          projection,
          pageSize,
          page,
        },
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error retrieving experiment:", error);
    throw error;
  }
}

export async function getExperimentById(id: string): Promise<IExperiment> {
  try {
    const response = await Api.get({
      url: `${envs.BASE_URL}/experiments/${id}`,
    });
    return response.data;
  } catch (error) {
    console.error("Error retrieving experiment:", error);
    throw error;
  }
}

export async function createExperiment(
  experiment: IExperimentRequest
): Promise<IExperiment> {
  try {
    const response = await Api.post({
      url: `${envs.BASE_URL}/experiments/create`,
      body: experiment,
    });

    if (response?.code) {
      return response;
    }
    return response.data;
  } catch (error) {
    console.error("Error creating experiment:", error);
    throw error;
  }
}

export async function updateExpriment(
  experiment: IExperimentRequest
): Promise<IExperiment> {
  try {
    const response = await Api.patch({
      url: `${envs.BASE_URL}/experiments/${experiment.id}`,
      body: experiment,
    });
    return response;
  } catch (error) {
    console.error("Error updating experiment:", error);
    throw error;
  }
}

export async function publishExperiment(experimentId: string): Promise<any> {
  try {
    const response = await Api.post({
      url: `${envs.BASE_URL}/experiments/publish/${experimentId}`,
    });
    return response;
  } catch (error) {
    console.error("Error publishing experiment:", error);
    throw error;
  }
}

export async function cancelAnExperiment(experimentId: string): Promise<any> {
  try {
    const response = await Api.post({
      url: `${envs.BASE_URL}/experiments/cancel/${experimentId}`,
    });
    return response;
  } catch (error) {
    console.error("Error updating experiment:", error);
    throw error;
  }
}

export async function finishAnExperiment(experimentId: string): Promise<any> {
  try {
    const response = await Api.post({
      url: `${envs.BASE_URL}/experiments/stop/${experimentId}`,
    });
    return response;
  } catch (error) {
    console.error("Error updating experiment:", error);
    throw error;
  }
}

export async function exportExperimentJson(
  experimentId: string
): Promise<ExportExperiment> {
  try {
    const response = await Api.get({
      url: `${envs.BASE_URL}/experiments/export/${experimentId}`,
    });

    if (response.code) return response;
    return response.data;
  } catch (error) {
    console.error("Error creating experiment:", error);
    throw error;
  }
}

export async function getValidationAudiencePocIds(
  country: string
): Promise<string[]> {
  try {
    const response = await Api.get({
      url: `${envs.BASE_URL}/validation-audience/pocIds`,
      config: {
        headers: {
          country,
        },
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting pocIds:", error);
    throw error;
  }
}

export async function publishExperimentValidation(
  experimentId: string
): Promise<any> {
  try {
    const response = await Api.post({
      url: `${envs.BASE_URL}/experiments/publish-validation/${experimentId}`,
    });
    return response;
  } catch (error) {
    console.error("Error publishing experiment validation:", error);
    throw error;
  }
}

export async function stopExperimentValidation(
  experimentId: string
): Promise<any> {
  try {
    const response = await Api.post({
      url: `${envs.BASE_URL}/experiments/stop-validation/${experimentId}`,
    });
    return response;
  } catch (error) {
    console.error("Error ending experiment validation:", error);
    throw error;
  }
}
