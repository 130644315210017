import { useQuery } from "react-query";
import { getValidationAudiencePocIds } from "../../../../services/experiment/experimentService";

export const useGetValidationAudiencePocIdsQuery = (country?: string) => {
  const query = useQuery({
    queryFn: () => getValidationAudiencePocIds(country),
    queryKey: ["validationAudiencePocIds", country],
    refetchOnWindowFocus: false,
    retry: 5,
    enabled: !!country,
  });

  return query;
};
