import { IExperiment } from "@/types/experiment";
import { Grid, Heading, Paragraph } from "@hexa-ui/components";
import { FormattedMessage } from "react-intl";
import { IActionLink } from "../../types/common";
import CardHeading from "../CardHeading/CardHeading";

const ExperimentResults = ({
  experiment,
  action,
}: {
  experiment: IExperiment;
  action: IActionLink;
}): JSX.Element => {
  const { Container, Item } = Grid;

  return (
    <Item sm={12} style={{ marginTop: 20 }}>
      <Container>
        <CardHeading
          heading={
            <Heading size="H3">
              <FormattedMessage id="experiment_details.results.title" />
            </Heading>
          }
          action={action}
        />
        <Item lg={9} md={9} sm={9} xl={9} xs={9}>
          <div style={{ width: "100%" }}>
            <div style={{ marginTop: 30 }}>
              <Heading className="labelHeading" size="H5">
                <FormattedMessage id="experiment_details.results.sub_title" />
              </Heading>
              <Paragraph className="mt-1">{experiment?.result}</Paragraph>
            </div>
          </div>
        </Item>
      </Container>
    </Item>
  );
};

export default ExperimentResults;
