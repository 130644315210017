import { Alert, Button, Card, Dialog, Heading } from "@hexa-ui/components";
import { X } from "@hexa-ui/icons";
import { TypeToast } from "admin-portal-shared-services";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from "react-query";
import { useCancelExperimentMutation } from "../../hooks/queries/experiments/useCancelExperimentMutation/useCancelExperimentMutation";
import { useStopExperimentMutation } from "../../hooks/queries/experiments/useStopExperimentMutation/useStopExperimentMutation";
import { IExperiment } from "../../types/experiment";
import { SET_TIMEOUT_TOAST_DURATION } from "./constants";
import {
  DialogContentContainer,
  DialogContentHeader,
  DialogUlList,
  InputRadio,
  cssProps,
} from "./styles";

const CustomDialog = ({
  setHandleModal,
  handleModal,
  handleToast,
  experimentId,
  refetchGetExperimentById,
}: CustomDialogProps) => {
  const Intl = useIntl();

  const finishAnExperimentMutation = useStopExperimentMutation(experimentId);
  const cancelAnExperimentMutation = useCancelExperimentMutation(experimentId);

  const [endExperimentRef, setEndExperimentRef] = useState<string>("");

  const handleConfirm = async () => {
    let response: any = undefined;
    const keyMessage = {
      finish: "Completed",
      cancel: "Canceled",
    };
    const message = `The experiment was ended and will appear as`;
    if (endExperimentRef === "finish")
      response = await finishAnExperimentMutation.mutateAsync(experimentId);
    if (endExperimentRef === "cancel")
      response = await cancelAnExperimentMutation.mutateAsync(experimentId);
    if (response?.status === 204) {
      refetchGetExperimentById();
      handleToast(
        `${message} ${keyMessage[endExperimentRef]}.`,
        true,
        TypeToast.SUCCESS,
        SET_TIMEOUT_TOAST_DURATION
      );
    } else {
      handleToast(
        "Unable to end experiment. Try again.",
        true,
        TypeToast.ERROR,
        SET_TIMEOUT_TOAST_DURATION
      );
    }
    setEndExperimentRef("");
    setHandleModal(false);
  };

  const handleCloseModal = () => {
    setEndExperimentRef("");
    setHandleModal(false);
  };

  const endExperimentOptionsList = [
    {
      id: "cancel",
      label: Intl.formatMessage({
        id: "transactional_custom_dialog.end_experiment.options.cancel",
      }),
    },
    {
      id: "finish",
      label: Intl.formatMessage({
        id: "transactional_custom_dialog.end_experiment.options.finish",
      }),
    },
  ];

  return (
    <>
      <Dialog.Root closeButton={false} open={handleModal}>
        <DialogContentContainer>
          <DialogContentHeader>
            <Heading height={"32px"} size="H2">
              <FormattedMessage
                id={`transactional_custom_dialog.end_experiment.header`}
              />
            </Heading>
            <X
              onClick={() => handleCloseModal()}
              cursor={"pointer"}
              size="tiny"
              style={{
                color: "#000000",
                backgroundColor: "rgba(20, 20, 20, 0.08)",
                borderRadius: "64px",
                width: "32px",
                height: "32px",
                padding: "8px",
              }}
            />
          </DialogContentHeader>
          <DialogUlList>
            {endExperimentOptionsList.map((option) => (
              <li>
                <Card
                  elevated="minimal"
                  border="small"
                  css={{
                    ...cssProps.DialogCardCss,
                    border:
                      endExperimentRef === option.id && "4px solid #0F7FFA",
                  }}
                >
                  <InputRadio
                    onClick={() => setEndExperimentRef(option.id)}
                    type="radio"
                    name="end_experiment"
                    id="end_experiment"
                    data-testid={`${option.id}_experiment`}
                  />
                  <span style={{ marginLeft: "7px" }}>{option.label}</span>
                </Card>
              </li>
            ))}
          </DialogUlList>
          <Alert
            style={{ width: "100%", height: "56px" }}
            type="info"
            message={Intl.formatMessage({
              id: "transactional_custom_dialog.end_experiment.alert",
            })}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "671px",
              overflow: "hidden",
            }}
          >
            <Button
              data-testid={`custom-dialog-close`}
              onClick={() => handleCloseModal()}
              size="medium"
              style={{ marginRight: "0.9375rem" }}
              variant="secondary"
            >
              <FormattedMessage
                id={`transactional_custom_dialog.end_experiment.go_back`}
              />
            </Button>

            <Button
              onClick={handleConfirm}
              data-testid={`custom-dialog-confirm`}
              size="medium"
              variant="primary"
              style={{
                backgroundColor: !!endExperimentRef && "#E53C32",
                color: !!endExperimentRef && "#FFFFFF",
              }}
              disabled={!endExperimentRef}
            >
              <FormattedMessage
                id={`transactional_custom_dialog.end_experiment.confirm`}
              />
            </Button>
          </div>
        </DialogContentContainer>
      </Dialog.Root>
    </>
  );
};

type CustomDialogProps = {
  handleModal: boolean;
  setHandleModal: React.Dispatch<React.SetStateAction<boolean>>;
  experimentId: string;
  handleToast: (
    message: string,
    isOpened: boolean,
    type?: TypeToast,
    duration?: number
  ) => void;
  refetchGetExperimentById: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<IExperiment, unknown>>;
};

export default CustomDialog;
