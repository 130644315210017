import { useMutation } from "react-query";
import { publishExperimentValidation } from "../../../../services/experiment/experimentService";
export const usePublishExperimentValidation = (experimentId: string) => {
  const mutation = useMutation({
    mutationFn: (experimentId: string) =>
      publishExperimentValidation(experimentId),
    mutationKey: ["publishExperimentValidation", experimentId],
  });
  return mutation;
};
