import { HelpCircle } from "@hexa-ui/icons";
import { styled } from "@hexa-ui/theme";
import { StepColumnForm } from "../styles";

export const Step1BasicInformationFormColumn = styled(StepColumnForm, {
  "&.column-config": {
    padding: 0,
    margin: 0,
    marginTop: "24px",
    width: "1255px",
  },
});

export const GuideLineLinkContainer = styled("span", {
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
  ".textLink": {
    fontWeight: "500",
  },
  ".helpCircleIcon": {
    color: "rgb(3,99,196)",
    marginRight: "5px",
  },
});

export const HelpCircleIcon = styled(HelpCircle, {
  color: "rgb(3,99,196)",
  marginRight: "5px",
});
