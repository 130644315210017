import { createContext, useContext, useState } from "react";

type StepperProviderProps = {
  children: React.ReactNode;
};

type StepperProviderType = {
  stepper: number;
  handleSteppers: (step: number) => void;
};

export const StepperContext = createContext<StepperProviderType>({
  stepper: 0,
  handleSteppers: (step: number) => {},
} as StepperProviderType);

export const useSteppers = () => useContext(StepperContext);

export const StepperProvider: React.FC<StepperProviderProps> = ({
  children,
}) => {
  const [stepper, setStepper] = useState<number>(0);

  const handleSteppers = (step: number) => {
    setStepper(step);
  };

  return (
    <StepperContext.Provider
      value={{
        stepper,
        handleSteppers,
      }}
    >
      {children}
    </StepperContext.Provider>
  );
};

export default StepperProvider;
