export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isValidExportResponse = (data) => {
  if (
    data &&
    (data?.code?.toString()?.includes("ERR_BAD_RESPONSE") ||
      data?.code?.toString().startsWith("4") ||
      data?.code?.toString().startsWith("5"))
  ) {
    return;
  }

  return true;
};

export const formatToPercentage = (value: string): string => {
  if (!value) {
    return "-";
  }

  return `${Number(value) * 100}%`;
};

export const mountTestId = (testId: string, value: string) => {
  const text = (value.replace(/ /g, "-").replace(/,/g, "").replace(/\(/, "").replace(/\)/, "")).toLowerCase();
  return `${testId}-${text}`;
};