import { styled } from "@hexa-ui/theme";

export const StyledExperimentWrapper = styled("div", {
  paddingBottom: "64px",
  paddingTop: "24px",
  ".px": {
    "&-0": {
      paddingLeft: "0",
      paddingRight: "0",
    },
  },
  ".labelHeading": {
    position: "initial",
    color: "var(--colors-interfaceLabelPrimary)",
    fontFamily: "var(--fonts-paragraph)",
    fontSize: "var(--fontSizes-2)",
    fontWeight: "var(--fontWeights-semibold)",
    lineHeight: "var(--lineHeights-3)",
  },
  ".card": {
    marginTop: "16px",
    padding: "24px",
    width: "100%",
  },
});

export const TooltipWrapper = styled("div", {
  position: "absolute",
  right: "-30px",
  top: "-4px",
});
