import {
  Alert,
  Button,
  Chip,
  Grid,
  Heading,
  IconButton,
  Input,
  Paragraph,
  Select,
  TextLink,
} from "@hexa-ui/components";
import { Plus, Trash2, X } from "@hexa-ui/icons";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import JsonInputField from "../../../components/JsonInputField/JsonInputField";
import PocIdsDialog from "../../../components/PocIdsDialog/PocIdsDialog";
import { setExperimentMutation } from "../../../store/ducks/ExperimentMutation/ExperimentMutationDuck";
import { useExperimentMutation } from "../../../store/hooks/ExperimentMutation/ExperimentMutationHook";
import {
  VARIATIONS_ALLOW_LIST_MAX_NUMBER,
  VARIATIONS_MAX_NUMBER,
  VariationsExperimentationTypes,
} from "../../../types/common";
import {
  ExperimentInputErrors,
  IApplicationSections,
  IFormVariation,
} from "../../../types/experiment";
import * as ExperimentMutationStyles from "../styles";
import { useDialog } from "./../../../context/DialogContext/dialogProvider";
import {
  handleError,
  handleStep3FormFields,
  isItValidJson,
  isValidHtml,
} from "./../../../pages/ExperimentMutation/utils";
import * as Styles from "./styles";
import { mountTestId } from "./../../../utils/utils";

type Props = {
  inputErrors: {
    variant_b: string[];
    variant_c: string[];
    variant_d: string[];
    variant_e: string[];
    variant_f: string[];
    variant_g: string[];
  };
  setinputFormError: React.Dispatch<
    React.SetStateAction<ExperimentInputErrors>
  >;
  dataApplicationSections: IApplicationSections;
};

const Step3 = ({
  inputErrors,
  setinputFormError,
  dataApplicationSections,
}: Props): JSX.Element => {
  const { Item } = Grid;

  const {
    experimentMutation: { variations: variants },
  } = useExperimentMutation();
  const dispatch = useDispatch();

  const intl = useIntl();

  const defaultVariant: IFormVariation = {
    id: "variant_id",
    name: "",
    description: "",
    applicationSection: "",
    experimentType: "",
    configObject: null,
    sdui: null,
    webView: null,
    copy: null,
    viewers: [],
  };

  const [pocIdDialog, setOpenDialog] = useState<{
    status: boolean;
    variantId: string;
  }>({ status: false, variantId: "" });

  const [isDialogInputValid, setIsDialogInputValid] = useState<any>({
    status: true,
    message: "",
  });

  const { openDialog, closeDialog } = useDialog();

  const variantIdRef = React.useRef<any>({
    value: { inputName: "", variantId: "" },
  });

  const resetExperimentationFieldsOnTypeChange = (
    inputName: string,
    variant: IFormVariation,
    value: string
  ) => {
    if (inputName === "applicationSection") {
      return {
        ...variant,
        experimentType: "",
        configObject: null,
        sdui: null,
        webView: null,
        copy: null,
      };
    }

    // setinputFormError(previous => ({
    //   step1: [],
    //   step2: [],
    //   step3: { ...previous, [variant.id]: [] },
    // }));
    // setinputFormError((prevErrors) => ({
    //   step1: [],
    //   step2: [],
    //   step3: { ...prevErrors.step3, [variant.id]: [] },
    // }));
    return {
      ...variant,
      configObject: null,
      sdui:
        value === VariationsExperimentationTypes.SDUI
          ? {
              containerId: "",
              sduiJson: "",
            }
          : null,
      webView:
        value === VariationsExperimentationTypes.WEBCONTAINER ||
        value === VariationsExperimentationTypes.WEBVIEW
          ? {
              containerId: "",
              html: "",
            }
          : null,
      copy:
        value === VariationsExperimentationTypes.COPY_TEST
          ? { androidStringKey: "", stringValue: "" }
          : null,
    };
  };

  const handleFieldsBasedOnSelect = (
    inputName: string,
    value: string,
    variantId: string
  ) => {
    const variant = variants.find((variant) => variant.id === variantId);
    let variantWithResetedAplicationTypes = variant;

    if (inputName === "experimentType" || inputName === "applicationSection") {
      variantIdRef.current.value = { inputName, variantId };
      variantWithResetedAplicationTypes =
        resetExperimentationFieldsOnTypeChange(
          inputName,
          variantWithResetedAplicationTypes,
          value
        );
    }

    const newVariant = handleStep3FormFields({
      ...variantWithResetedAplicationTypes,
      [inputName]: value,
    });

    handleOnChangeVariant(inputName, value, variantId, newVariant);
  };

  const updateNestedProperty = (obj, path, value) => {
    const keys = path.split("."); // Split the path into an array of keys
    const lastKey = keys.pop(); // Get the last key

    // Create a deep copy of the object
    const copy = JSON.parse(JSON.stringify(obj));
    let nested = copy; // Start with the copied object

    keys.forEach((key) => {
      // If the key doesn't exist, create an empty object
      if (!nested[key]) {
        nested[key] = {};
      }
      nested = nested[key]; // Traverse deeper
    });

    // Set the value at the last key
    nested[lastKey] = value;
    return copy; // Return the modified copy of the object
  };

  const handleOnChangeVariant = (
    inputName: string,
    value: string,
    variantId: string,
    newVariant?: IFormVariation
  ) => {
    const errors = handleError(inputName, value, inputErrors[variantId]);
    dispatch(
      setExperimentMutation({
        variations: [
          ...variants.map((variant) => {
            if (variant.id === variantId) {
              const updatedVariant = newVariant || { ...variant };
              return updateNestedProperty(updatedVariant, inputName, value);
            }
            return variant;
          }),
        ],
      })
    );
    errors &&
      setinputFormError({
        step1: [],
        step2: [],
        step3: { ...inputErrors, [variantId]: errors },
      });
  };

  const ids = ["b", "c", "d", "e", "f", "g"];
  const getLastId = (list) => {
    if (list.length === 0) return ids[0];

    return ids[list.length];
  };

  useEffect(() => {
    if (variants?.length === 0) {
      handleVariantCreation();
    }
    if (variantIdRef.current.value.inputName === "experimentType") {
      const omitFields = ["configObject", "sdui", "webView", "copy"];
      const errors = inputErrors[variantIdRef.current.value.variantId].filter(
        (error) => !omitFields.includes(error)
      );
      setinputFormError((prevErrors) => ({
        step1: [],
        step2: [],
        step3: {
          ...prevErrors.step3,
          [variantIdRef.current.value.variantId]: errors,
        },
      }));
    }
  }, [variants]);

  const errorVerify = (id: string, fieldName?: string) => {
    if (inputErrors) {
      const hasErrors = inputErrors[id]?.includes(fieldName);
      return hasErrors;
    }
  };

  const removeErrorVerify = (id: string, fieldName?: string) => {
    if (inputErrors) {
      const hasErrors = inputErrors[id]?.includes(fieldName);
      if (hasErrors) {
        const filtered = inputErrors[id].filter((error) => error !== fieldName);
        setinputFormError({
          step1: [],
          step2: [],
          step3: { ...inputErrors, [id]: filtered },
        });
        return;
      }
      return hasErrors;
    }
  };

  const handleVariantCreation = () => {
    const newVariant = { ...defaultVariant };
    newVariant.id = `variant_${getLastId(variants)}`;
    dispatch(
      setExperimentMutation({
        variations: [...variants, newVariant],
      })
    );
  };

  const handleDeleteVariant = (variantId: string) => {
    const filteredVariations = variants.filter(
      (variant) => variant.id !== variantId
    );
    setinputFormError({
      step1: [],
      step2: [],
      step3: { ...inputErrors, errors: false, [variantId]: [] },
    });
    dispatch(
      setExperimentMutation({
        variations: filteredVariations.map((variant, index) => {
          return {
            ...variant,
            id: `variant_${ids[index]}`,
          };
        }),
      })
    );
  };

  const handleAddViewer = () => {
    setOpenDialog({ status: false, variantId: "" });
  };

  const handleDeleteViewer = (variantId: string, viewerId: string) => {
    const index = variants.findIndex((variant) => variant.id === variantId);
    const viewers = variants[index].viewers.filter(
      (viewer) => viewer !== viewerId
    );
    dispatch(
      setExperimentMutation({
        variations: [
          ...variants.map((variant) => {
            if (variant.id === variantId) {
              return {
                ...variant,
                viewers: viewers,
              };
            }
            return variant;
          }),
        ],
      })
    );
  };

  const getCopyTestContent = (variant: IFormVariation) => {
    return (
      <Item className="item-input-row flex-column justify-space-between full-width">
        <ExperimentMutationStyles.ExperimentFormHeader
          css={{ marginTop: 0, width: "100%" }}
        >
          <Heading size="H5">
            <FormattedMessage id="create_and_update_experiment.labels.step3.variants.types.copyTests" />
          </Heading>
        </ExperimentMutationStyles.ExperimentFormHeader>

        <Item className="item-input-row mt-16 justify-space-between full-width gap-20">
          <Styles.Step3FormControlCardInput
            css={{
              $$width_form_control_input: "616px",
              $$marginTop_form_control_input: "",
              $$height_form_control_input: "72px",
            }}
          >
            <Select.Root
              id="input-type"
              label="Copy key"
              defaultValue={variant?.copy?.androidStringKey}
              value={variant?.copy?.androidStringKey}
              error={
                errorVerify(variant?.id, "androidStringKey")
                  ? `${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.copy_key" })}`
                  : ""
              }
              onChange={(e: any) => {
                handleFieldsBasedOnSelect(
                  "copy.androidStringKey",
                  e,
                  variant?.id
                );
                removeErrorVerify(variant?.id, "androidStringKey");
              }}
              data-testid={`copyTest-androidStringKey-select-type-${variant?.id}`}
              name="copy.androidStringKey"
            >
              {dataApplicationSections?.applicationSections &&
                dataApplicationSections?.applicationSections
                  ?.find((applicationSection) => {
                    return (
                      applicationSection.applicationSectionCode ===
                      variant?.applicationSection
                    );
                  })
                  ?.types?.find(
                    (type) =>
                      variant?.experimentType === type.experimentTypeCode
                  )
                  ?.copyKeys?.map((item, index) => (
                    <Select.Option
                      key={`copyTest-item-${item}-${index}`}
                      value={item}
                      data-testid={`copyTest-item-${item}-${index}`}
                    >
                      {item}
                    </Select.Option>
                  ))}
            </Select.Root>
          </Styles.Step3FormControlCardInput>
          <Styles.Step3FormControlCardInput
            css={{
              $$width_form_control_input: "617px",
              $$marginTop_form_control_input: "",
              $$height_form_control_input: "72px",
            }}
          >
            <Input
              label="Copy"
              name="stringValue"
              placeholder="Enter copy"
              hasError={errorVerify(variant?.id, "stringValue")}
              errorText={`${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.copy" })}`}
              value={variant?.copy?.stringValue}
              data-testid={`input-copy-${variant?.id}`}
              width={"100%"}
              onChange={(e: any) => {
                handleOnChangeVariant(
                  "copy.stringValue",
                  e.target.value,
                  variant?.id
                );
                removeErrorVerify(variant?.id, "stringValue");
              }}
            />
          </Styles.Step3FormControlCardInput>
        </Item>
      </Item>
    );
  };

  const getServerDrivenUiContent = (variant: IFormVariation) => {
    return (
      <Item className="item-input-row flex-column justify-space-between full-width">
        <ExperimentMutationStyles.ExperimentFormHeader
          css={{ marginTop: 0, width: "100%" }}
        >
          <Heading size="H5">
            <FormattedMessage id="create_and_update_experiment.labels.step3.variants.types.sdui" />
          </Heading>
        </ExperimentMutationStyles.ExperimentFormHeader>

        <Item className="item-input-row mt-16 justify-space-between full-width">
          <Styles.Step3FormControlCardInput
            css={{
              $$width_form_control_input: "616px",
              $$marginTop_form_control_input: "",
              $$height_form_control_input: "72px",
            }}
          >
            <Select.Root
              id="input-type"
              label="Container ID"
              defaultValue={variant?.sdui?.containerId}
              value={variant?.sdui?.containerId}
              error={
                errorVerify(variant?.id, "containerId")
                  ? `${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.container_id" })}`
                  : ""
              }
              onChange={(e: any) => {
                handleFieldsBasedOnSelect("sdui.containerId", e, variant?.id);
                removeErrorVerify(variant?.id, "containerId");
              }}
              data-testid={`sdui-containerId-select-type-${variant?.id}`}
              name="sdui.containerId"
            >
              {dataApplicationSections?.applicationSections &&
                dataApplicationSections?.applicationSections
                  ?.find(
                    (applicationSection) =>
                      applicationSection?.applicationSectionCode.toLowerCase() ===
                      variant?.applicationSection?.toLowerCase()
                  )
                  ?.types?.find(
                    (type) =>
                      variant?.experimentType === type.experimentTypeCode
                  )
                  ?.containerIds.map((item, index) => (
                    <Select.Option
                      key={`sdui-item-${item}-${index}`}
                      value={item}
                      data-testid={`sdui-item-${item}-${index}`}
                    >
                      {item}
                    </Select.Option>
                  ))}
            </Select.Root>
          </Styles.Step3FormControlCardInput>
        </Item>
        <Item className="item-input-row mt-16 justify-space-between full-width">
          <Styles.Step3FormControlCardInput
            css={{
              $$width_form_control_input: "1255px",
              $$marginTop_form_control_input: "",
              $$height_form_control_input: "72px",
            }}
          >
            <JsonInputField
              jsonValue={variant?.sdui?.sduiJson}
              name="sdui.sduiJson"
              inputErrors={errorVerify(variant?.id, "sduiJson")}
              id={`textarea-sdui-${variant?.id}-sdui`}
              label="JSON"
              width={"100%"}
              variant={variant}
              changeAction={handleOnChangeVariant}
              removeErrorVerify={(e) => {
                if (isItValidJson(e.target.value))
                  removeErrorVerify(variant?.id, "sduiJson");
              }}
            />
          </Styles.Step3FormControlCardInput>
        </Item>
      </Item>
    );
  };

  const getWebViewContent = (variant: IFormVariation) => {
    return (
      <Item className="item-input-row flex-column justify-space-between full-width">
        <ExperimentMutationStyles.ExperimentFormHeader
          css={{ marginTop: 0, width: "100%" }}
        >
          <Heading size="H5">
            <FormattedMessage id="create_and_update_experiment.labels.step3.variants.types.webContainer" />
          </Heading>
        </ExperimentMutationStyles.ExperimentFormHeader>

        <Item className="item-input-row mt-16 justify-space-between full-width">
          <Styles.Step3FormControlCardInput
            css={{
              $$width_form_control_input: "616px",
              $$marginTop_form_control_input: "",
              $$height_form_control_input: "72px",
            }}
          >
            <Select.Root
              id="input-type"
              label="Container ID"
              defaultValue={variant?.webView?.containerId}
              value={variant?.webView?.containerId}
              error={
                errorVerify(variant?.id, "containerId")
                  ? `${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.container_id" })}`
                  : ""
              }
              onChange={(e: any) => {
                handleFieldsBasedOnSelect(
                  "webView.containerId",
                  e,
                  variant?.id
                );
                removeErrorVerify(variant?.id, "containerId");
              }}
              data-testid={`webView-containerId-select-type-${variant?.id}`}
              name="webView.containerId"
            >
              {dataApplicationSections?.applicationSections &&
                dataApplicationSections?.applicationSections
                  ?.find((applicationSection) => {
                    return (
                      applicationSection.applicationSectionCode.toLowerCase() ===
                      variant?.applicationSection
                    );
                  })
                  ?.types?.find(
                    (type) =>
                      variant?.experimentType === type.experimentTypeCode ||
                      type?.experimentTypeCode.toString() ===
                        VariationsExperimentationTypes.WEBCONTAINER
                  )
                  ?.containerIds.map((item, index) => (
                    <Select.Option
                      key={`webView-item-${item}-${index}`}
                      value={item}
                      data-testid={`webView-item-${item}-${index}-web-container`}
                    >
                      {item}
                    </Select.Option>
                  ))}
            </Select.Root>
          </Styles.Step3FormControlCardInput>
        </Item>
        <Item className="item-input-row mt-16 justify-space-between full-width">
          <Styles.Step3FormControlCardInput
            css={{
              $$width_form_control_input: "1255px",
              $$marginTop_form_control_input: "",
              $$height_form_control_input: "72px",
            }}
          >
            <JsonInputField
              jsonValue={variant?.webView?.html}
              name="webView.html"
              inputErrors={errorVerify(variant?.id, "html")}
              id={`textarea-webView-${variant?.id}-web-container`}
              label="HTML"
              width={"100%"}
              variant={variant}
              changeAction={handleOnChangeVariant}
              removeErrorVerify={(e) => {
                if (isValidHtml(e.target.value))
                  removeErrorVerify(variant?.id, "html");
              }}
            />
          </Styles.Step3FormControlCardInput>
        </Item>
      </Item>
    );
  };

  const getApplicationCodedContent = (variant: IFormVariation) => {
    return (
      <Item className="item-input-row flex-column justify-space-between full-width">
        <ExperimentMutationStyles.ExperimentFormHeader
          css={{ marginTop: 0, width: "100%" }}
        >
          <Heading size="H5">
            <FormattedMessage id="create_and_update_experiment.labels.step3.variants.types.applicationCoded" />
          </Heading>
        </ExperimentMutationStyles.ExperimentFormHeader>

        <Item className="item-input-row mt-16 justify-space-between full-width">
          <JsonInputField
            jsonValue={variant?.configObject}
            name="configObject"
            inputErrors={errorVerify(variant?.id, "configObject")}
            id={`textarea-configObject-${variant?.id}`}
            label="JSON"
            width={"100%"}
            variant={variant}
            changeAction={handleOnChangeVariant}
          />
        </Item>
      </Item>
    );
  };

  const renderCompontentBasedOnSelectedType = (variant: IFormVariation) => {
    if (!variant?.experimentType) return <></>;

    return (
      <Styles.StyledContainer>
        <Item className="item-input-row justify-space-between full-width">
          <Styles.Step3FormControlCardInput
            css={{
              $$width_form_control_input: "100%",
              $$marginTop_form_control_input: "",
              $$height_form_control_input: "106px",
            }}
          >
            {variant?.experimentType ===
              VariationsExperimentationTypes.APPLICATION_CODED && (
              <>{getApplicationCodedContent(variant)}</>
            )}

            {variant?.experimentType ===
              VariationsExperimentationTypes.COPY_TEST && (
              <>{getCopyTestContent(variant)}</>
            )}

            {variant?.experimentType ===
              VariationsExperimentationTypes.SDUI && (
              <>{getServerDrivenUiContent(variant)}</>
            )}

            {(variant?.experimentType ===
              VariationsExperimentationTypes.WEBCONTAINER ||
              variant?.experimentType ===
                VariationsExperimentationTypes.WEBVIEW) && (
              <>{getWebViewContent(variant)}</>
            )}
          </Styles.Step3FormControlCardInput>
        </Item>
      </Styles.StyledContainer>
    );
  };

  const handleApplicationSectionChange = (
    inputName: string,
    value: string,
    variantId: string
  ) => {
    const variant = variants.find((variant) => variant.id === variantId);
    if (!variant?.applicationSection || !variant?.experimentType) {
      handleFieldsBasedOnSelect(inputName, value, variantId);
    } else {
      if (
        inputName === "applicationSection" &&
        variant.applicationSection !== value
      ) {
        openDialog(
          "Change Application section?",
          "If you do this, all the information you've entered below will be lost.",
          null,
          {
            action: () => {
              handleFieldsBasedOnSelect(inputName, value, variantId);
              closeDialog();
            },
            dialogActionText: "Yes, change",
            dialogActionButtonType: "destructive",
          },
          {
            action: () => closeDialog(),
            dialogActionText: "No, go back",
            dialogActionButtonType: "secondary",
          }
        );
        return;
      }

      if (inputName === "experimentType" && variant.experimentType !== value) {
        openDialog(
          "Change type?",
          "If you do this, all the information you've entered below will be lost.",
          null,
          {
            action: () => {
              handleFieldsBasedOnSelect(inputName, value, variantId);
              closeDialog();
            },
            dialogActionText: "Yes, change",
            dialogActionButtonType: "destructive",
          },
          {
            action: () => closeDialog(),
            dialogActionText: "No, go back",
            dialogActionButtonType: "secondary",
          }
        );
        return;
      }
    }
  };

  const handleGoBack = () => {
    setIsDialogInputValid({
      status: true,
      message: "",
    });
    setOpenDialog({ status: false, variantId: "" });
  };

  return (
    <ExperimentMutationStyles.ExperimentStepsWrapper data-testid="step-3">
      <Alert
        style={{ width: "100%", marginTop: "32px" }}
        message={`${intl.formatMessage({ id: "create_and_update_experiment.labels.step3.alert.title" })}`}
      />
      <ExperimentMutationStyles.ExperimentStepsCard
        css={{
          $$customMarginTop: "16px",
          $$padding: 0,
          $$height_experiment_steps_wrapper: "108px",
        }}
        ref={{
          current: "[Circular]" as any,
        }}
        border="medium"
        elevated="small"
      >
        <ExperimentMutationStyles.StepColumnForm
          css={{
            $$flex_direction: "column",
            height: "60px",
          }}
        >
          <ExperimentMutationStyles.ExperimentFormHeader css={{ marginTop: 0 }}>
            <Heading size="H3">
              <FormattedMessage id="create_and_update_experiment.labels.step3.control_version.title" />
            </Heading>
          </ExperimentMutationStyles.ExperimentFormHeader>
          <span className="text-secondary-color">
            <FormattedMessage id="create_and_update_experiment.labels.step3.control_version.paragraph" />
          </span>
        </ExperimentMutationStyles.StepColumnForm>
      </ExperimentMutationStyles.ExperimentStepsCard>

      <ExperimentMutationStyles.ExperimentStepsCard
        key={"Step3VariantsFormColumn-2"}
        css={{
          $$customMarginTop: "16px",
          $$padding: "0 0 24px 0",
          $$height_experiment_steps_wrapper: "auto",
        }}
        ref={{
          current: "[Circular]" as any,
        }}
        border="medium"
        elevated="small"
      >
        <ExperimentMutationStyles.ExperimentFormHeader>
          <Heading size="H3">
            <FormattedMessage id="create_and_update_experiment.labels.step3.title" />
          </Heading>
        </ExperimentMutationStyles.ExperimentFormHeader>

        {variants?.length > 0 &&
          variants?.map((variant, index) => (
            <>
              <Styles.Step3VariantsFormColumn
                key={`${variant?.id}-${index}`}
                id="Step3VariantsFormColumn"
                className="margin-config"
                css={{ width: "1255px" }}
              >
                <Item className="item-form-header">
                  <ExperimentMutationStyles.ExperimentFormHeader
                    css={{ marginTop: 0 }}
                  >
                    <Heading size="H4">
                      Variant{" "}
                      {String.fromCharCode(
                        "b".charCodeAt(0) + index
                      ).toLocaleUpperCase()}
                    </Heading>
                  </ExperimentMutationStyles.ExperimentFormHeader>
                  {variants?.length > 1 && (
                    <IconButton
                      icon={Trash2}
                      data-testid={`icon-delete-variant-${variant?.id}`}
                      variant="tertiary"
                      type="button"
                      onClick={() => handleDeleteVariant(variant?.id)}
                    />
                  )}
                </Item>

                <Item className="item-input-row mt-24 justify-space-between custom-width-1255">
                  <Styles.Step3FormControlCardInput
                    css={{
                      $$width_form_control_input: "619px",
                      $$marginTop_form_control_input: "",
                      $$height_form_control_input: "72px",
                    }}
                  >
                    <Input
                      label="Variant name"
                      placeholder="Enter variant name"
                      value={variant?.name}
                      hasError={errorVerify(variant?.id, "name")}
                      errorText={`${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.variant_name" })}`}
                      onChange={(e: any) =>
                        handleOnChangeVariant(
                          "name",
                          e.target.value,
                          variant?.id
                        )
                      }
                      data-testid={`input-variant-name-${variant?.id}`}
                      width={"100%"}
                      name="name"
                    />
                  </Styles.Step3FormControlCardInput>
                  <Styles.Step3FormControlCardInput
                    css={{
                      $$width_form_control_input: "617px",
                      $$marginTop_form_control_input: "",
                      $$height_form_control_input: "72px",
                    }}
                  >
                    <Input
                      label="Variant description"
                      placeholder="Enter variant description"
                      width={"100%"}
                      value={variant?.description}
                      hasError={errorVerify(variant?.id, "description")}
                      errorText={`${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.variant_description" })}`}
                      data-testid={`input-variant-description-${variant?.id}`}
                      name="description"
                      onChange={(e: any) =>
                        handleOnChangeVariant(
                          "description",
                          e.target.value,
                          variant?.id
                        )
                      }
                    />
                  </Styles.Step3FormControlCardInput>
                </Item>

                <Item className="item-input-row mt-32 custom-width-1255 flex-column ">
                  <Item className="item-input-row custom-width-651 gap-20">
                    <Styles.Step3FormControlCardInput
                      css={{
                        $$width_form_control_input: "298px",
                        $$marginTop_form_control_input: "",
                        $$height_form_control_input: "72px",
                      }}
                    >
                      <Select.Root
                        id="input-application-selection"
                        label="Application section"
                        defaultValue={variant?.applicationSection || ""}
                        value={variant?.applicationSection || ""}
                        disabled={!dataApplicationSections}
                        error={
                          errorVerify(variant?.id, "applicationSection")
                            ? `${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.application_section" })}`
                            : ""
                        }
                        onChange={(e: any) => {
                          handleApplicationSectionChange(
                            "applicationSection",
                            e,
                            variant?.id
                          );
                        }}
                        data-testid={`select-application-section-${variant?.id}`}
                        name="applicationSection"
                      >
                        {dataApplicationSections &&
                          dataApplicationSections?.applicationSections.map(
                            (item, index) => (
                              <Select.Option
                                key={`variant-${variant?.id}-select-application-section-${item.applicationSectionCode}-${index}`}
                                value={item?.applicationSectionCode}
                                data-testid={`variant-${variant?.id}-select-application-section-${item.applicationSectionCode}`}
                              >
                                {item?.name}
                              </Select.Option>
                            )
                          )}
                      </Select.Root>
                    </Styles.Step3FormControlCardInput>

                    <Styles.Step3FormControlCardInput
                      css={{
                        $$width_form_control_input: "298px",
                        $$marginTop_form_control_input: "",
                        $$height_form_control_input: "72px",
                      }}
                    >
                      <Select.Root
                        id="input-type"
                        label="Type"
                        defaultValue={variant?.experimentType || ""}
                        value={variant?.experimentType || ""}
                        error={
                          errorVerify(variant?.id, "experimentType")
                            ? `${intl.formatMessage({ id: "create_and_update_experiment.error_messages.step3.experiment_type" })}`
                            : ""
                        }
                        onChange={(e: any) =>
                          handleApplicationSectionChange(
                            "experimentType",
                            e,
                            variant?.id
                          )
                        }
                        data-testid={`select-experiment-type-${variant?.id}`}
                        name="experimentType"
                        disabled={!variant?.applicationSection}
                      >
                        {dataApplicationSections?.applicationSections &&
                          dataApplicationSections?.applicationSections
                            ?.find(
                              (applicationSection) =>
                                applicationSection?.applicationSectionCode.toLowerCase() ===
                                variant?.applicationSection?.toLowerCase()
                            )
                            ?.types.map((item) => (
                              <Select.Option
                                key={`variant-${variant?.id}-select-type-section-${mountTestId("item", item.name)}`}
                                value={item.experimentTypeCode}
                                data-testid={`variant-${variant?.id}-select-type-section-${mountTestId("item", item.name)}`}
                              >
                                {item.name}
                              </Select.Option>
                            ))}
                      </Select.Root>
                    </Styles.Step3FormControlCardInput>
                  </Item>

                  {renderCompontentBasedOnSelectedType(variant)}
                </Item>

                <Item
                  lg={12}
                  md={12}
                  sm={12}
                  xl={12}
                  xs={12}
                  style={{ marginTop: 30 }}
                >
                  <div style={{ width: "100%" }}>
                    <Paragraph size="small" style={{ fontWeight: 600 }}>
                      <FormattedMessage id="create_and_update_experiment.labels.step3.variants.section_allow_list.title" />{" "}
                      <span
                        style={{
                          fontSize: "var(--fontSizes-1)",
                          fontWeight: "var(--fontWeights-normal)",
                          color: "var(--colors-interfaceLabelSecondary)",
                          paddingBottom: "var(--space-1)",
                        }}
                      >
                        (
                        <FormattedMessage id="create_and_update_experiment.labels.step3.variants.section_allow_list.optional" />
                        )
                      </span>
                    </Paragraph>
                    <div
                      style={{
                        marginTop: 10,
                        display: "flex",
                        alignItems: "center",
                      }}
                      data-testid="div-select-viewers"
                    >
                      {variant?.viewers?.map((viewer, index) => (
                        <div key={`${viewer}-${index}`}>
                          <Chip.Root
                            disabled={true}
                            style={{ marginRight: 10 }}
                          >
                            <Chip.Label
                              data-testid={`viewer-${variant?.id}-${viewer}`}
                            >
                              {`POC: ${viewer}`}
                            </Chip.Label>
                            <IconButton
                              style={{ marginLeft: "5px" }}
                              icon={X}
                              data-testid={`delete-chip-${variant?.id}-${viewer.replace(" ", "-")}`}
                              variant="tertiary"
                              type="button"
                              size="tiny"
                              onClick={() =>
                                handleDeleteViewer(variant?.id, viewer)
                              }
                            />
                          </Chip.Root>
                        </div>
                      ))}

                      <div>
                        <TextLink
                          style={{
                            pointerEvents:
                              variant?.viewers &&
                              variant?.viewers?.length >=
                                VARIATIONS_ALLOW_LIST_MAX_NUMBER
                                ? ("none" as React.CSSProperties["pointerEvents"])
                                : ("auto" as React.CSSProperties["pointerEvents"]),
                          }}
                          data-testid="link-add-viewer"
                          onClick={() =>
                            setOpenDialog({
                              status: true,
                              variantId: variant?.id,
                            })
                          }
                        >
                          +{" "}
                          <FormattedMessage id="create_and_update_experiment.buttons.step3.add_viewer" />
                        </TextLink>
                      </div>
                      <PocIdsDialog
                        openDialog={pocIdDialog}
                        handleAddViewer={handleAddViewer}
                        handleGoBack={handleGoBack}
                        variantId={pocIdDialog?.variantId}
                      />
                    </div>
                  </div>
                </Item>
                <Styles.HorizontalDivision orientation="horizontal" />
              </Styles.Step3VariantsFormColumn>
            </>
          ))}
        <Styles.AddVariantArea>
          <Button
            icon={Plus}
            data-testid="button-create-variant"
            size="medium"
            type="button"
            leading
            variant="secondary"
            onClick={() => handleVariantCreation()}
            disabled={variants?.length >= VARIATIONS_MAX_NUMBER}
          >
            <FormattedMessage id="create_and_update_experiment.buttons.step3.create_variant" />
          </Button>
        </Styles.AddVariantArea>
      </ExperimentMutationStyles.ExperimentStepsCard>
    </ExperimentMutationStyles.ExperimentStepsWrapper>
  );
};

export default Step3;
