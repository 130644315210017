import * as Grid from "@admin-portal-shared-components/grid";
import { ReactNode } from "react";
import * as Styles from "./styles";

type Props = {
  children: ReactNode;
};

export default function PageWrapper({ children }: Props) {
  return (
    <Styles.PageWrapper>
      <Grid.Container
        type="fluid"
        sidebar
        style={{
          width: "100%",
          margin: "0",
          padding: "0",
          display: "block",
        }}
      >
        {children}
      </Grid.Container>
    </Styles.PageWrapper>
  );
}
