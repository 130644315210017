import { Paragraph } from "@hexa-ui/components";
import { MoreHorizontal } from "@hexa-ui/icons";
import { styled } from "@hexa-ui/theme";
import { MenuItem, MenuList, Popover } from "@mui/material";
import PauseIconSVGComponent from "./PauseIconSVGComponent";

export const PopoverContainer = styled(Popover, {
  borderRadius: "16px",
  boxShadow: "0 4px 16px 0 rgba(0,0,0,0.16)",
});

export const ActionsButton = styled(MoreHorizontal, {
  cursor: "pointer",
});

export const MenuListCss = styled(MenuList, {
  padding: 0,
  borderRadius: "8px",
  boxShadow: "none",
  width: "194px",
});

export const MenuItemCss = styled(MenuItem, {
  height: "56px",
  padding: 0,
  width: "100%",
});

export const MenuItemParagraph = styled(Paragraph, {
  display: "flex",
  alignItems: "center",
  padding: 0,
  width: "100%",
  span: {
    marginLeft: "4px",
  },
});

export const PauseIcon = styled(PauseIconSVGComponent, {});
