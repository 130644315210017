import { styled } from "@hexa-ui/theme";
import { ExperimentFormControlCardInput, StepColumnForm } from "../styles";

export const Step2AudiencesFormColumn = styled(StepColumnForm, {});

export const Step2FormControlCardInput = styled(
  ExperimentFormControlCardInput,
  {
    display: "flex",
    flexDirection: "$$flex_direction",
    justifyContent: "$$justify_content",
  }
);
