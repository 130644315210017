import { LoadingDots } from "@hexa-ui/components";
import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageWrapper from "./components/PageWrapper/PageWrapper";
import { envs } from "./config/env";
import DialogProvider from "./context/DialogContext/dialogProvider";
import LoadingProvider from "./context/LoadingContext/loadingProvider";
import MetricProvider from "./context/MetricsContext/metricProvider";
import StepperProvider from "./context/StepperContext/stepperProvider";
import ToastConfigProvider from "./context/ToastConfigContext/toastConfigProvider";
import useAppBar from "./hooks/useSideBar";
import ExperimentDetails from "./pages/ExperimentDetails/ExperimentDetails";
import ExperimentMutation from "./pages/ExperimentMutation/ExperimentMutation";
import ExperimentsList from "./pages/ExperimentsList/ExperimentsList";
import { store } from "./store";

const Router = (): JSX.Element => {
  useAppBar();

  const queryClient = new QueryClient();

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <LoadingProvider>
          <DialogProvider>
            <PageWrapper>
              <MetricProvider>
                <StepperProvider>
                  <ToastConfigProvider>
                    <BrowserRouter>
                      <Routes>
                        <Route
                          path={`${envs.URL_BASE}/`}
                          element={
                            <Suspense fallback={<LoadingDots />}>
                              <ExperimentsList />
                            </Suspense>
                          }
                        />
                        <Route
                          path={`${envs.URL_BASE}/edit/:id`}
                          element={
                            <Suspense fallback={<LoadingDots />}>
                              <ExperimentMutation />
                            </Suspense>
                          }
                        />
                        <Route
                          path={`${envs.URL_BASE}/new-experiment`}
                          element={
                            <Suspense fallback={<LoadingDots />}>
                              <ExperimentMutation />
                            </Suspense>
                          }
                        />
                        <Route
                          path={`${envs.URL_BASE}/experiment/:id`}
                          element={
                            <Suspense fallback={<LoadingDots />}>
                              <ExperimentDetails />
                            </Suspense>
                          }
                        />
                      </Routes>
                    </BrowserRouter>
                  </ToastConfigProvider>
                </StepperProvider>
              </MetricProvider>
            </PageWrapper>
          </DialogProvider>
        </LoadingProvider>
      </QueryClientProvider>
    </Provider>
  );
};
export default Router;
