import { Table } from "@hexa-ui/components";
import { FormattedMessage } from "react-intl";
import { Pagination } from "../../pages/ExperimentsList/ExperimentsList";
import { StyledExperimentListTable } from "./styles";

type CustomTableProps = {
  isLoading: boolean;
  data: any[];
  columns: CustomTableColumns[];
  search: boolean;
  pagination: Pagination;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
  onRowClick?: (rowData, isRowClick: React.MouseEvent<any>) => void;
};

type CustomTableColumns = {
  header: string;
  accessor: string;
  disableSortBy: boolean;
  width: number | string;
};
const CustomTable = ({
  isLoading,
  data,
  columns,
  search,
  pagination,
  setPagination,
  onRowClick,
}: CustomTableProps) => {
  return (
    <StyledExperimentListTable id="experiment-list-table">
      <Table
        data-testid="experiment-list-table"
        key={pagination.page}
        loading={isLoading}
        columns={columns.map((column) => {
          return {
            Header: <FormattedMessage id={column.header} />,
            accessor: column.accessor,
            disableSortBy: column.disableSortBy,
            width: column.width,
          };
        })}
        data={data}
        pagination={{
          pageSize: pagination.pageSize,
          pageSizeOptions: [5, 10, 20],
          showPageSizeSelector: true,
          total: pagination.totalElements,
          current: pagination.page + 1,
          onChange(page, pageSize) {
            setPagination({ page: page - 1, pageSize });
          },
        }}
        onRow={(rowData) => {
          return {
            onClick: (event: React.MouseEvent<HTMLTableRowElement>) => {
              onRowClick(rowData, event);
            },
          };
        }}
        search={search}
      />
    </StyledExperimentListTable>
  );
};
export default CustomTable;
